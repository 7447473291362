import dat from 'dat.gui';
import { Color, FogExp2 } from 'three';
import { demo, forcePalette } from './demo';
import { getFogIntesityFromFov, settings } from './gui';
import { city, scene } from './script';
import { frandom, map, randomArr, setRandomSeed } from "./utils";

const paletteTemplate = (() => {
    const col = '#ffffff'
    const obj = {}

    const single = ['background', 'linesA', 'linesB']
    const array = ['facadesRectangles', 'facadesCylinders', 'billboards']
    const array_multiple = ['rectangles', 'cylinders', 'triangles']

    for (let prop of single) obj[prop] = col
    for (let prop of array) obj[prop] = [col,col,col]
    for (let prop of array_multiple) {
        for (let i = 1; i <= 6; i++) {
            let propName = prop + '_' + i
            obj[propName] = [col,col,col]
        }
    }
    
    return obj
})()

export let colorGUI
export const color_settings = {
    currentPalette: 'Palette 1',
    lines_gradient: false,
    randomize: randomizeColors,
    randomPalette: randomPalette,
}

export let paletteColors
let palette

let saved_palettes = [
    // Palette 1
    {
        rectangle: ["#D56E2D", "#F1CB67", "#D74927", "#F4B84A", "#C75044", "#F4EC43", "#E2BFA9", "#B38E78"],
        cylinder: ["#AC6BAA", "#8E97B7", "#CDD6F0", "#BF636D", "#AB2641", "#E86E88", "#FFD3EC", "#E4659E", "#CBA7B9"],
        triangle: ["#55B9AF", "#84BBFF", "#D7E5ED", "#316FE5", "#49B2C9", "#5EEBE2", "#367BB2", "#ADE2F2"],
        environment: ["#84BBFF", "#D7E5ED", "#ADE2F2", "#8E97B7", "#CDD6F0", "#C75044", "#D56E2D", "#F4EC43", "#D74927"],
        background: ["#ECDCD1", "#F7D6DC"],
    },
    // Palette 5
    {
        rectangle: ["#66DB5D", "#66FA00", "#357643", "#6496BB", "#306897", "#49908D", "#B9DCE3", "#B8DDF9"],
        cylinder: ["#FFAADC", "#F8E7E8", "#D9AEBC", "#E19DA6", "#D8A584", "#D6C2CC", "#D79CBF", "#D6BDBE", "#DAA5AD", "#D0AE99"],
        triangle: ["#99A5F3", "#B480F6", "#CFCFF9", "#7C99EB", "#6670E4", "#606CD0", "#A47FE1", "#E3CDFB", "#D9CDFF", "#CBB0D6"],
        environment: ["#357643", "#66DB5D", "#49908D", "#B9DCE3", "#CBB0D6", "#CFCFF9", "#E3CDFB", "#E19DA6", "#D79CBF", "#D6C2CC"],
        background: ["#ECFFFF", "#37686E", "#6E597B"]
    },
    // Palette 6
    {
        rectangle: ["#59AC9F", "#ADF2E9", "#C7EAE6", "#335E60", "#57A781", "#AEE5CB", "#D8C1D1", "#ECD0E9", "#E3D8F5"],
        cylinder: ["#3A6D2B", "#6D9E41", "#43875B", "#5D5E2C", "#F1F9E6", "#EBC7C2", "#F9E2DF"],
        triangle: ["#284B6E", "#3669A7", "#4A9AD9", "#6EADC7", "#4284AB", "#2DAAC3"],
        environment: ["#E3D8F5", "#4284AB", "#D8C1D1", "#2DAAC3", "#3669A7", "#F1F9E6", "#43875B", "#AEE5CB", "#57A781"],
        background: ["#132227", "#302543", "#1e3955"],
    },
    // Palette 7 
    {
        rectangle: ["#FFCAC6", "#FFC9D6", "#AE3969", "#AB565F", "#D2ABB5"],
        cylinder: ["#523550", "#A5A8C9", "#605B8F", "#A09BE5", "#8B4870", "#C3BAD7", "#F4E7F3", "#957A85"],
        triangle: ["#B1251B", "#D37466", "#FF927A", "#7E1715", "#E2C9A5", "#6C5A54", "#E3D8CE", "#E2813D"],
        environment: ["#7E1715", "#DE911F", "#D37466", "#FF927A", "#5D6981", "#D1DCF1", "#8492AE", "#E2C9A5", "#6C5A54"],
        background: ["#E4EAF5", "#FCECEB"]
    },
    // Palette 8 
    {
        rectangle: ["#EE7500", "#EE8F34", "#FFBF48", "#872A14", "#B8511E", "#EA9E93", "#F0C299"],
        cylinder: ["#4EAC75", "#45973E", "#6FE6AC", "#60D067", "#00B676", "#A5CB9D", "#356F6D", "#13CEC1", "#E4CE70", "#A1902D", "#15B408"],
        triangle: ["#469CCA", "#3588D5", "#50C6EA", "#153892", "#73B0BF", "#0095C3", "#2678EB"],
        environment: ["#EE7500", "#F1A63A", "#00B676", "#6FE6AC", "#1A3D98", "#0095C3", "#4EAC75", "#A5CB9D"],
        background: ["#E4EAF5", "#f2dddb", "#F0EAC3", "#124c5f"]
    },
    // Palette 9 
    {
        rectangle: ["#A5B0B0", "#BCC3AB", "#5CA0B0", "#AFCBD6", "#0C2657", "#6870C8", "#6DBABB", "#A0CDDC"],
        cylinder: ["#CEBA81", "#82993D", "#BDAF36", "#E4D75A", "#D8D770", "#74B352", "#609C40", "#7AEE91", "#B2C38C", "#FAF2C1"],
        triangle: ["#BF8894", "#BD745E", "#D88E92", "#E8AE94", "#F0D1B2", "#D19D9A", "#894453", "#903C3A", "#C29A78"],
        environment: ["#B4AFBA", "#BD745E", "#E8AE94", "#D8D770", "#0C2657", "#903C3A", "#B2C38C", "#CEBA81"],
        background: ["#EAE2E4", "#F0E8EE", "#E5E5DF"],
    },
    // Palette 10 
    {
        rectangle: ["#BF538D", "#C34186", "#A02B2A", "#F575BB", "#E2BBC0", "#E79AFF", "#CA408D", "#E4B7E4"],
        cylinder: ["#7687AF", "#CAE0E6", "#50A4BA", "#79AEDC", "#AB7594", "#70609C", "#874DA9", "#BC56B6", "#78BED9", "#ACABE6", "#3696A3", "#C5D6E1"],
        triangle: ["#7DC297", "#253428", "#DFFEE9", "#689D6D", "#A1F2BF", "#4B643D", "#88CE98", "#6CB2AA", "#749F9E"],
        environment: ["#70609C", "#CA408D", "#F575BB", "#7DC297", "#253428", "#689D6D", "#E79AFF", "#E4B7E4"],
        background: ["#EEE2DA", "#e7f2f5"],
    },
    // Palette 11 
    {
        rectangle: ["#8CA9AF", "#61BCEA", "#4B81B0", "#377B99", "#11A6B4", "#0791DD", "#58A9C2", "#798999", "#DEE7FC", "#93A7C2"],
        cylinder: ["#8B6D98", "#C1ADCA", "#9163B7", "#A75D8A", "#B18D9F", "#F1C2E8", "#D17DAE", "#9692EA"],
        triangle: ["#DFA052", "#FBECCC", "#FEA488", "#F6C96B", "#E98C34", "#E8917A", "#D3AB8B"],
        environment: ["#D58673", "#FEA488", "#E98C34", "#DFDCE6", "#8B6D98", "#C1ADCA", "#A75D8A", "#B1A8A6", "#CFB688"],
        background: ["#122439", "#110101", "#351A23"],
    },
    // Palette 12 
    {
        rectangle: ["#ABDCDC", "#739BB6", "#4D717A", "#1C8EBA", "#8DBFDD", "#3DB0C0", "#142AC6"],
        cylinder: ["#97FF78", "#6CA470", "#53B035", "#345464", "#D9EBCF", "#BCD2AE", "#44D23F", "#83A9A3"],
        triangle: ["#C05490", "#8E81D7", "#E35DFF", "#96245D", "#574679", "#AC94A4", "#5356E8", "#4731A6", "#DFBDF8", "#B7ACDD"],
        environment: ["#7ED541", "#6CA470", "#97FF78", "#1C8EBA", "#B7ACDD", "#009BB0", "#5E5796", "#345464", "#83A9A3"],
        background: ["#D9E3DE"],
    },
    // Palette 13 
    {
        rectangle: ["#4039CB", "#5DBAA9", "#57A188", "#049C92", "#92B47B", "#98C7A9", "#1E41B7", "#26569E"],
        cylinder: ["#77243A", "#7126D5", "#A95A8B", "#B552CB", "#BB5F7D", "#5615C6", "#701B36", "#9140FF"],
        triangle: ["#AD4A2E", "#FF8A52", "#EB8955", "#C87957", "#892C33", "#CE682F", "#F38219", "#FC9033"],
        environment: ["#98C7A9", "#57A188", "#049C92", "#26569E", "#4039CB", "#C87957", "#892C33", "#CE682F"],
        background: ["#FAEAE4", "#F2D6DF", "#302936"],
    },
    // Palette 15 
    {
        rectangle: ["#5A3658", "#3E2639", "#6B2B47", "#081C9A", "#469BC2"],
        cylinder: ["#364836", "#605B25", "#2B6140", "#B1822B", "#DAA24A", "#F5BD35"],
        triangle: ["#4EADD5", "#0037C3", "#BBE1F2", "#17B7DB", "#8AD6DC", "#032BED", "#3980D4"],
        environment: ["#4EADD5", "#BBE1F2", "#17B7DB", "#3980D4", "#032BED", "#469BC2", "#081C9A", "#6B2B47", "#5A3658", "#3E2639"],
        background: ["#020913", "#B7D4D3"],
    },
    // Palette 17 
    {
        rectangle: ["#5E4E82", "#AEA6D8", "#828ED5", "#BCA3DD", "#9EA3CC", "#6A3A42", "#E2ACBE", "#F5E3D8", "#4B2422"],
        cylinder: ["#5083B7", "#C2F9F7", "#D4FEFE", "#9FCEDB", "#5199C2", "#A6C5C5", "#5F93A9", "#70A5E5", "#7FC3EB"],
        triangle: ["#677E68", "#BFE2DA", "#466E24", "#8E8C69", "#96B7AF", "#507D29", "#7B807C", "#DAE1D8", "#D3CBC5", "#B3B2A0"],
        environment: ["#466E24", "#BFE2DA", "#677E68", "#8E8C69", "#A6C5C5", "#B3E2F1", "#DAE1D8", "#5F93A9", "#D3CBC5", "#B3B2A0", "#5199C2", "#70A5E5", "#5083B7", "#C2F9F7", "#7FC3EB"],
        background: ["#EAFBFE", "#EBE6DF", "#02171F", "#1A0507"],
    },
    // Palette 18 
    {
        rectangle: ["#EDDAB8", "#CAB29B", "#9F6233", "#F9F6DA", "#D8BC98", "#774F29", "#17326E", "#172882", "#122FB1", "#4590A8", "#6EA9BA", "#62C0D5"],
        cylinder: ["#DD9238", "#E9B848", "#E6783D", "#FFDB77", "#C7773B", "#FBE6A8"],
        triangle: ["#17326E", "#8ED5D9", "#172882", "#B5DCF4", "#86BEB6", "#68AAC6", "#122FB1", "#60C9CC", "#4590A8", "#6EA9BA", "#97DEDB", "#62C0D5"],
        environment: ["#8C8973", "#E9E5CA", "#DCD1C1", "#EBBE53", "#E59E3B", "#17326E", "#86BEB6", "#68AAC6", "#6EA9BA"],
        background: ["#F8F1D5", "#F5E6CE", "#281D11"],
    },
    // Palette 19 
    {
        rectangle: ["#535BB5", "#6789B1", "#7D87E0", "#C7D6E1", "#8DCDA1", "#7AAE76", "#777E57", "#5A70A5", "#9EB1FF", "#CFE7F9", "#70AC7B", "#8EBFC2", "#85DDB4", "#D8E2B9"],
        cylinder: ["#89527D", "#CEB1C8", "#E5CCF0", "#824183", "#AEB3F7", "#61397C", "#D1B1D4", "#D48690"],
        triangle: ["#F3CFC1", "#662321", "#B46A64", "#903E44", "#EEB3A1", "#DEA57D"],
        environment: ["#535BB5", "#6789B1", "#777E57", "#8DCDA1", "#9EB1FF", "#CFE7F9", "#70AC7B", "#8EBFC2", "#85DDB4", "#D8E2B9", "#F3F8D8", "#61397C", "#7D87E0", "#C7D6E1"],
        background: ["#12151C", "#282A29", "#F7E0CC", "#FAE9D8"],
    },
    // Palette 20 
    {
        rectangle: ["#A42D51", "#EA348B", "#531367", "#5B113C", "#883C77", "#8D2036", "#732C51", "#F93FAD", "#402581"],
        cylinder: ["#74F882", "#387F48", "#6EEB5F", "#489C87", "#6DC88E", "#78F0C2"],
        triangle: ["#4693EA", "#273BB0", "#0860BF", "#27597F", "#6AA9B9", "#4EA2F6"],
        environment: ["#F0AD51", "#DF8D56", "#EAC071", "#4EA2F6", "#0860BF", "#273BB0", "#402581", "#883C77", "#531367", "#5B113C", "#A42D51", "#F93FAD"],
        background: ["#FFF0D7", "#FCE9F7"],
    },
    // Palette 21 
    {
        rectangle: ["#7999EF","#8CB9F9","#C798E4","#C7C0E4","#DDCAE1","#8EC6F8","#7288F1","#B3E5EF","#B6BEEE","#B3ECF3","#9EA2F4","#C2E8EF","#B2D2E3","#A6C7FA"],
        cylinder:  ["#F5C1DC","#F9F0FE","#F0ABB1","#F4BBB2","#DEAFDF","#F19CF8","#F2A6D3","#EFC2F3","#FAE2E0"],
        triangle: ["#F8F2DE","#BEF5EC","#DBEBF0","#E3EDC9","#DCF7DD","#FEF6B9","#FEFCDE","#CAF6DD","#E2E3D4"],
        environment: ["#F5C1DC","#F9F0FE","#F0ABB1","#F4BBB2","#DEAFDF","#F19CF8","#F2A6D3","#EFC2F3","#FAE2E0","#B6BEEE","#DDCAE1","#9EA2F4","#C798E4","#C7C0E4"],
        background: ["#503250","#3A3C69"],
    },
    // Palette 22
    {
        rectangle: ["#A0D990","#5CC797","#9CEE9D","#B4D085","#6BC99A","#A1E27E","#52B4B1","#C9BE5E"],
        cylinder:  ["#F2D296","#F9CC8E","#E1AF6D","#3A7FAA","#95A3B3","#539EA5","#4C90AE"],
        triangle: ["#834899","#6861A6","#445197","#4E689F","#945C9A","#A25CC3","#AB8ABB"],
        environment: ["#95A3B3","#9CEE9D","#A1E27E","#52B4B1","#539EA5","#4C90AE","#3A7FAA","#445197"],
        background: ["#263452","#252C57"],
    },
    // Palette 24
    {
        rectangle: ["#71BAB9","#C7ECC5","#C4C991","#A2C1A7","#45858F","#E4EAB6","#9EDADA","#7FBA9B","#6EC3BE","#71C7E6","#8FCAE1","#A2EFBF"],
        cylinder:  ["#AE6487","#B476B9","#7A519C","#BC65A2","#8B4D9F","#BA797C"],
        triangle: ["#E9BF7C","#D1A772","#F2F5BF","#F4F2CC","#E5E38E","#B78F6D"],
        environment: ["#E9BF7C","#D1A772","#F2F5BF","#F4F2CC","#E5E38E","#B78F6D","#7FBA9B","#A2C1A7","#C7ECC5","#A2EFBF","#AE6487","#BA797C"],
        background: ["#192128","#152834","#292929"],
    },
    // Palette 25
    {
        rectangle: ["#D0835D","#DD9D5F","#E4AB66","#DA9F31","#CF875E","#B76195","#BE6D89","#DD959E"],
        cylinder:  ["#6B5CB6","#8D5CAA","#755DC6","#9F61CE","#B85CBF","#4D83BC","#5680B2","#1888BD","#4A6DBC"],
        triangle: ["#FFF0AF","#F2CF77","#DBA65B","#E7B74E","#EBD789","#C59956"],
        environment: ["#6B5CB6","#8D5CAA","#755DC6","#B76195","#BE6D89","#DD959E","#CF875E","#DD9D5F","#E4AB66","#DA9F31"],
        background: ["#2D2E2A","#525151"],
    },
    // Palette 28
    {
        rectangle: ["#CE5153","#F0B1A3","#AD2419","#DE894B","#EB8FAA","#FFB186","#DF5E8A","#D49A92","#B67A94","#E6D4AF","#ECE2C6","#F3E2C6"],
        cylinder:  ["#D2EFEF","#4E9BA9","#428AAC","#ACA58F","#8DAFFD","#7BA7CF","#255383","#38B7D8","#77A5B5","#BFD4C6","#E0E0CC"],
        triangle: ["#544C7B","#3D65D5","#F0E9EB","#8C98CF","#6B5C8B","#C2BDC0"],
        environment: ["#B67A94","#F0B1A3","#EB8FAA","#DF5E8A","#D49A92","#7BA7CF","#77A5B5","#428AAC","#FFB186","#AD2419","#DE894B","#255383"],
        background: ["#EFF0F0","#F7F1E2"],
    },
    // Palette 29
    {
        rectangle: ["#325369","#295993","#78ABB8","#163779","#CAE8E6","#4696A7","#30B6CE","#0A82B4","#B2CECE","#718F95","#A3AFA9"],
        cylinder:  ["#D25027","#E15D19","#CD3938","#BD2925","#8A1B22","#DFD3B7","#AC9A7A","#C69C87"],
        triangle: ["#EEB56F","#A7535D","#D490A1","#EDBEAD","#E7C2A7","#92677D","#FFC0A2","#E79075","#A32348","#F9C2CE","#FFC0BB"],
        environment: ["#EEB56F","#A7535D","#D490A1","#EDBEAD","#E7C2A7","#92677D","#FFC0A2","#E79075","#A32348","#F9C2CE","#FFC0BB"],
        background: ["#0F0107"],
    },
];
   
export const palettes = (() => {
    try {
        PALETTE_TEMPLATES;
        console.log('Loaded Palettes from .js')
        return PALETTE_TEMPLATES
    } catch {
        console.log('Loaded Palettes from bundle')
        return saved_palettes
    }
})()

// Set the palette currently in use
export function setPalette(id) {
    paletteColors = demo ? forcePalette : palettes[id]
    palette = paletteTemplate
}

// Select a random palette
export function randomPalette() {
    const paletteNames = new Array(palettes.length).fill().map((_,i) => 'Palette ' + (i+1))
    setRandomSeed(Math.random()*Number.MAX_SAFE_INTEGER)
    let pid = frandom(palettes.length)
    setRandomSeed(city.seed)
    color_settings.currentPalette = paletteNames[pid]
    setPalette(pid)
    randomizeColors()
}

// Initialize the palette GUI
export function initPalettes() {
    setPalette(0)

    if (!demo) {
        colorGUI = new dat.GUI()
        const paletteNames = new Array(palettes.length).fill().map((_,i) => 'Palette ' + (i+1))
        colorGUI.add(color_settings, 'currentPalette', paletteNames).onChange((p) => {
            setPalette(paletteNames.indexOf(p))
            randomizeColors()
        })
        colorGUI.add(color_settings, 'lines_gradient').onChange(() => city.init()).name('Gradient Lines')
        colorGUI.add(color_settings, 'randomPalette').name('Random Palette')
        colorGUI.add(color_settings, 'randomize').name('Shuffle Colors')
        // colorGUI.add(color_settings, 'createPalette').name('Create New Palette')

        let rectangleFolder = colorGUI.addFolder('Rectangles')
        let cylinderFolder = colorGUI.addFolder('Cylinders')
        let triangleFolder = colorGUI.addFolder('Triangles')

        for (let [name, value] of Object.entries(palette)) {
            if (typeof(value) === 'object') {
                let elm = colorGUI
                if (name.includes('rectangles')) elm = rectangleFolder
                else if (name.includes('cylinders')) elm = cylinderFolder
                else if (name.includes('triangles')) elm = triangleFolder

                let folder = elm.addFolder(name)

                for (let [index, color] of Object.entries(value)) {
                    let settingName = name + '_' + index
                    color_settings[settingName] = color
                    folder.addColor(color_settings, settingName).onChange(updateColors)
                }
            } else {
                color_settings[name] = value
                colorGUI.addColor(color_settings, name).onChange(updateColors)
            }
        }
    } else {
        // Same code as above without the gui part
        for (let [name, value] of Object.entries(palette)) {
            if (typeof(value) === 'object') {
                for (let [index, color] of Object.entries(value)) {
                    let settingName = name + '_' + index
                    color_settings[settingName] = color
                }
            } else {
                color_settings[name] = value
            }
        }
    }

    randomizeColors()
}

export function randomizeColors(initCity = true) {    
    setRandomSeed(Math.random()*Number.MAX_SAFE_INTEGER)
    // setRandomSeed(city.seed)

    for (let e in color_settings) {
        if (typeof(color_settings[e]) === 'function' || e === 'currentPalette') continue
        if (e.toLowerCase().includes('rectangles')) color_settings[e] = randomArr(paletteColors.rectangle)
        else if (e.toLowerCase().includes('cylinders')) color_settings[e] = randomArr(paletteColors.cylinder)
        else if (e.toLowerCase().includes('triangles')) color_settings[e] = randomArr(paletteColors.triangle)
        else if (e.toLowerCase().includes('line')) color_settings[e] = randomArr(paletteColors.rectangle)
        else if (e.toLowerCase().includes('billboard')) color_settings[e] = randomArr(paletteColors.cylinder)
        else if (e === 'background') color_settings[e] = randomArr(paletteColors.background)
    }

    setRandomSeed(city.seed)

    if (colorGUI) colorGUI.updateDisplay()
    if (initCity) updateColors(initCity)
}

export function updateColors(initCity = true) {
    scene.background = new Color(color_settings.background)
    const fogColor = color_settings.background
    scene.fog = new FogExp2(fogColor, settings.fog_density * getFogIntesityFromFov())//new FogExp2(fogColor, 0.06)

    if (initCity) city.init()
}

// Returns an array of colors
export function getGradient(name, id) {
    let fullName = name + (id ? '_' + id : '')
    let colorNames = Object.keys(color_settings).filter(k => k.startsWith(fullName))
    
    return colorNames.map(name => new Color(color_settings[name])).reverse()
}

// returns a single color
export function getColorFromGradient(name, id, current, start, stop) {
    let gradient = getGradient(name, id)

    return gradient[Math.floor(map(current, start, stop, 0, gradient.length))]
}

// returns a single color
export function getInterpolatedColorFromGradient(name, id, current, start, stop) {
    let gradient = getGradient(name, id)
    let k = map(current, start, stop, 0, gradient.length-1)
    k = Math.min(gradient.length-2, k)
    let index = Math.floor(k)
    let colA = gradient[index]
    let colB = gradient[index+1]

    return colA.lerp(colB, k%1)
}

// function createRandomPalette() {
//     let newPalette = {}

//     const createPaletteArray = (minCol, maxCol) => {
//         let count = frandom(minCol, maxCol)
//         return new Array(count).fill().map(_ => crandom())
//     }

//     newPalette.rectangle = createPaletteArray(2, 6)
//     newPalette.cylinder = createPaletteArray(2, 6)
//     newPalette.triangle = createPaletteArray(2, 6)
//     newPalette.environment = createPaletteArray(2, 8)
//     newPalette.background = ['#0e1428']

//     paletteColors = newPalette
//     randomizeColors()
// }