import seedrandom from "seedrandom"
import SimplexNoise from "simplex-noise"
import { BufferAttribute, Color, Vector3 } from "three"

export const PI = Math.PI
export let rng = new seedrandom()
export let noise = new SimplexNoise()

export function setRandomSeed(seed) {
    rng = new seedrandom(seed)
    noise = new SimplexNoise(seed)
    console.log('set seed', seed)
}

// Random float
export function random(min, max) {
    if (min == null & max == null) return rng()
    if (max == null) return rng() * min
    return rng() * (max - min) + min
}

// Random element in array
export function randomArr(arr) {
    const id = frandom(arr.length-1)
    return arr[id]
}

// Random Boolean
export function brandom(r = 0.5) {
    return rng() < r
}

// Random Color
export function crandom() {
    return new Color(frandom(0xffffff))
}

// export function crandomHexa() {
//     return '#' + new Color(Math.floor(Math.random() * 0xffffff)).getHexString()
// }

// Random integer
export function frandom(min, max) {
    if (max == null) return Math.floor(random(min+1))
    return Math.floor(random(min, max+1))
}

// Random 3D Vector
export function vrandom(min=0, max=10) {
    return new Vector3(random(min, max), random(min, max), random(min, max))
}

// Random Sign
export function srandom() {
    return brandom() ? 1 : -1
}

// Random noise remapped in [min, max] range
export function noiseRange2D(x, y, min, max) {
    return (noise.noise2D(x, y)+1)/2 * (max-min) + min
}

export function map(n, start1, stop1, start2, stop2, easeFunc, constrain=false) {
    if (easeFunc) {
        let k = map(n, start1, stop1, 0, 1)
        return map(easeFunc(k), 0, 1, start2, stop2)
    }
    let t = (n - start1) / (stop1 - start1) * (stop2 - start2) + start2;
    return constrain ? clamp(t , start2, stop2) : t
}

export function cmap(n, start1, stop1, start2, stop2) {
    return clamp(map(n, start1, stop1, start2, stop2), start2, stop2)
}

export function clamp(n, min, max) {
    return Math.min(Math.max(n, min), max)
}

export function easeRandom(min, max, easeFunc=easeInExpo) {
    return map(random(min, max), min, max, min, max, easeFunc)
}

export function easeOutCirc(x) {
    return x*x*x*x;
}

export function easeInExpo(x) {
    return x === 0 ? 0 : Math.pow(2, 10 * x - 10);
}

export function easeInOutQuint(x) {
    return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2;
}

export function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

export function smoothstep(x, edge0 = 0.0, edge1 = 1.0) {
    let t = clamp((x - edge0) / (edge1 - edge0), 0, 1);
    return t * t * (3.0 - 2.0 * t);
} 

export function easeInOutQuad(x) {
    return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
}

// https://stackoverflow.com/questions/14627566/rounding-in-steps-of-20-or-x-in-javascript
export function roundToStep(number, increment, offset=0) {
    return Math.ceil((number - offset) / increment ) * increment + offset;
}

export function vroundToStep(v, increment, offset) {
    return new Vector3(roundToStep(v.x, increment, offset), roundToStep(v.y, increment, offset), roundToStep(v.z, increment, offset))
}

// export function shuffleArray(array) {
//     let currentIndex = array.length,  randomIndex;
  
//     // While there remain elements to shuffle...
//     while (currentIndex != 0) {
  
//       // Pick a remaining element...
//       randomIndex = Math.floor(Math.random() * currentIndex);
//       currentIndex--;
  
//       // And swap it with the current element.
//       [array[currentIndex], array[randomIndex]] = [
//         array[randomIndex], array[currentIndex]];
//     }
  
//     return array;
// }

 // Random from weighted array
export function randomFromWeightedArray(arr) {
    let r = random()
    let cumul = 0
    
    for (let elm of arr) {
    cumul += elm.freq
        if (r <= cumul) return elm
    }    
}

// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
export function pSBC(p,c0,c1,l) {
    // const convertToHexaString = (c) => c ? ('#' + (typeof(c) === 'number' ? c.toString(16) : c.getHexString())) : undefined
    // c0 = convertToHexaString(c0)
    // c1 = convertToHexaString(c1)

    let r,g,b,P,f,t,h,i=parseInt,m=Math.round,a=typeof(c1)=="string";
    if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
    const pSBCr=(d)=>{
        let n=d.length,x={};
        if(n>9){
            [r,g,b,a]=d=d.split(","),n=d.length;
            if(n<3||n>4)return null;
            x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
        }else{
            if(n==8||n==6||n<4)return null;
            if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
            d=i(d.slice(1),16);
            if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
            else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
        }return x};
    h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=pSBCr(c0),P=p<0,t=c1&&c1!="c"?pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
    if(!f||!t)return null;
    if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
    else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
    a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
    if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
    else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
}

// Geometry

export function createColorAttribute(color, numVerts) {
    const itemSize = 3;  // r, g, b
    const arr = color.toArray()
    const colors = new Uint8Array(itemSize * numVerts).map((v, ndx) => arr[ndx % 3]*255)

    return new BufferAttribute(colors, 3, true)
}

// function manualBoundingBox(geo) {
//     let array = geo.attributes.position.array;
//     let box = new Box3(new Vector3(1e8), new Vector3(-1e8));
  
//     // console.log(array)
//     for (let i = 0; i <= array.length - 3; i += 3) {
//       box.min.x = Math.min(box.min.x, array[i]);
//       box.max.x = Math.max(box.max.x, array[i]);
//       box.min.y = Math.min(box.min.y, array[i + 1]);
//       box.max.y = Math.max(box.max.y, array[i + 1]);
//       box.min.z = Math.min(box.min.z, array[i + 2]);
//       box.max.z = Math.max(box.max.z, array[i + 2]);
//     }
  
//     // console.log(box)
//     return box;
//   }
  

export function interceptCircleLineSeg(cx, cy, cr, x1, y1, x2, y2) { //, cx, cy, cr, circle, line) {
    const circle = {
        radius: cr,
        center: {
            x: cy,
            y: cy
        }
    }

    const line = {
        p1: {
            x: x1,
            y: y1
        },
        p2: {
            x: x2,
            y: y2
        }
    }

    var a, b, c, d, u1, u2, ret, retP1, retP2, v1, v2;
    v1 = {};
    v2 = {};
    v1.x = line.p2.x - line.p1.x;
    v1.y = line.p2.y - line.p1.y;
    v2.x = line.p1.x - circle.center.x;
    v2.y = line.p1.y - circle.center.y;
    b = (v1.x * v2.x + v1.y * v2.y);
    c = 2 * (v1.x * v1.x + v1.y * v1.y);
    b *= -2;
    d = Math.sqrt(b * b - 2 * c * (v2.x * v2.x + v2.y * v2.y - circle.radius * circle.radius));
    if(isNaN(d)){ // no intercept
        return [];
    }
    u1 = (b - d) / c;  // these represent the unit distance of point one and two on the line
    u2 = (b + d) / c;    
    retP1 = {};   // return points
    retP2 = {}  
    ret = []; // return array
    if(u1 <= 1 && u1 >= 0){  // add point if on the line segment
        retP1.x = line.p1.x + v1.x * u1;
        retP1.y = line.p1.y + v1.y * u1;
        ret[0] = retP1;
    }
    if(u2 <= 1 && u2 >= 0){  // second add point if on the line segment
        retP2.x = line.p1.x + v1.x * u2;
        retP2.y = line.p1.y + v1.y * u2;
        ret[ret.length] = retP2;
    }       
    return ret;
}

export const saveFile = function (strData, filename) {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
        document.body.appendChild(link); //Firefox requires the link to be in the body
        link.download = filename;
        link.href = strData;
        link.click();
        document.body.removeChild(link); //remove the link when done
    } else {
        location.replace(uri);
    }
}

let lastTime

export function updatePerformance(name) {
    const now = performance.now()
    
    if (name != null) {
        name = '-> ' + name
        if (lastTime != null) console.log(name, (now - lastTime)/1000 + 's')
        else console.log(name, now/1000 + 's')
    }

    lastTime = now
}