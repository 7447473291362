import {
    BufferGeometry,
    Color, DoubleSide,
    Euler,
    Float32BufferAttribute,
    Group,
    InstancedBufferAttribute,
    InstancedMesh, Matrix4, MeshBasicMaterial,
    PlaneGeometry,
    Points,
    PointsMaterial,
    Quaternion, Vector3
} from "three";
import { settings } from "./gui";
import { paletteColors } from "./palettes";
import { city, scene } from "./script";
import { brandom, PI, random, randomArr } from "./utils";

export class Environment {
  constructor() {
    this.radius = 45
  }

  init() {
    if (!this.group) {
      this.group = new Group();
    } else {
      this.group.clear();
      this.group.parent.remove(this.group);
    }

    this.starCount = settings.star_count;
    this.createSquareStars();

    this.createOptimizedLines(settings.environment_density, -settings.city_height, settings.city_height * 3)
    scene.add(this.group);

    // this.lines = [];

    // let density = settings.environment_density;
    // this.createLines(density, 0, settings.city_height * 1.5);
    // this.createLines(
    //   density,
    //   settings.city_height * 1.5,
    //   settings.city_height * 1.5
    // );
    // this.createLines(
    //   density,
    //   -settings.city_height * 1.5,
    //   settings.city_height * 1.5
    // );
    // scene.add(this.group);
  }

  createSquareStars() {
    const geometry = new BufferGeometry();
    const vertices = [];
    const colors = [];
    const r = this.radius*2;

    for (let i = 0; i < this.starCount; i++) {
      const x = random(-r / 2, r / 2);
      const y = random(-r / 2, r);
      const z = random(-r / 2, r / 2);

      vertices.push(x, y, z);
      let col = new Color(randomArr(paletteColors.environment));
      colors.push(...col.toArray());
    }

    geometry.setAttribute("position", new Float32BufferAttribute(vertices, 3));
    geometry.setAttribute("color", new Float32BufferAttribute(colors, 3));

    const material = new PointsMaterial({
      size: 0.2,
      sizeAttenuation: true,
      vertexColors: true,
    });
    const particles = new Points(geometry, material);
    this.group.add(particles);
  }

  createOptimizedLines(density = 0.5, startH = 0, endH = 10) {
    const planeGeometry = new PlaneGeometry(1, 1)
    const originalVertexArray = [...planeGeometry.attributes.position.array]

    const matrix = new Matrix4()
    const position = new Vector3()
    const rotation = new Euler()
    const quaternion = new Quaternion()
    const scale = new Vector3()

    const colors = []
    const color = new Color()

    const lines = []

    const makeLine = (x, y, z) => {
        const fat = brandom(0.05)
        const w = random(1, 5)
        const h = fat ? random() : random(0.005, 0.02)

        const horizontal = brandom(0.75)
        const yRot = brandom() ? 0 : PI/2
        const zRot = horizontal ? 0 : PI/2

        const hexColor = randomArr(paletteColors.environment)

        position.set(x, y, z)
        scale.set(w, h, 1)
        rotation.set(0, yRot, zRot)
        quaternion.setFromEuler(rotation)

        matrix.compose(position, quaternion, scale)

        if (fat) {
            planeGeometry.attributes.position.array = [...originalVertexArray]
            planeGeometry.applyMatrix4(matrix)
            planeGeometry.computeBoundingBox()

            if (city.boundingBox.intersectsBox(planeGeometry.boundingBox))
                return
        }

        color.set(hexColor)
        colors.push(color.r, color.g, color.b, 1)

        lines.push(matrix.clone())
    }

    // Create lines
    const r = this.radius
    const step = 2
    const minRadius = 2

    for (let k = -r; k < r; k += step) {
        for (let j = startH; j < endH; j += step) {
            for (let i = -r; i < r; i += step) {
                if (brandom(density) && Math.abs(i) > minRadius && Math.abs(k) > minRadius)
                    makeLine(i, j, k)
            }
        }
    }

    // Create optimized instanced geometry
    const geo = new PlaneGeometry(1, 1)
    const mat = new MeshBasicMaterial({ vertexColors: true, side: DoubleSide })
    const mesh = new InstancedMesh(geo, mat, lines.length)

    for (let i = 0; i < lines.length; i++) {
        mesh.setMatrixAt(i, lines[i])
    }

    // Add colors to geometry
    geo.setAttribute('color', new InstancedBufferAttribute(new Float32Array(colors), 4))

    // Add geometry to scene
    this.group.add(mesh)
  }

//   createLines(density = 0.3, yStart, height) {
//     const matrix = new Matrix4()
//     let maxDist = this.radius;
//     let step = 2;

//     const linesIntersectsAnother = (b, h, r) => {
//       for (let { box, rot, horizontal } of this.lines) {
//         if (rot === r && h === horizontal && box.intersectsBox(b)) return true;
//       }
//       return false;
//     };

//     const collidesWithCity = (line) => {
//         return city.boundingBox.intersectsBox(line)
//     }

//     const addLine = (p1, p2, rot, w, horizontal = true, fat=false) => {
//       const geoW = horizontal ? p2.clone().sub(p1).length() : w;
//       const geoH = horizontal ? w : p2.clone().sub(p1).length();
//       const geo = new PlaneGeometry(geoW, geoH);
//       geo.rotateY(rot);
//       geo.translate(p1.x, p1.y + geoH / 2, p1.z);
//       geo.computeBoundingBox();
//       let intersects = linesIntersectsAnother(geo.boundingBox, horizontal, rot) 
//                        || (fat && collidesWithCity(geo.boundingBox))

//       if (intersects) return false;

//       //   bendGeometry(geo)
//       const mat = new MeshBasicMaterial({
//         color: randomArr(paletteColors.environment),
//         side: DoubleSide,
//       });

//       const mesh = new Mesh(geo, mat);
//       this.group.add(mesh);
//       this.lines.push({
//         box: geo.boundingBox,
//         rot: rot,
//         horizontal: horizontal,
//         geo: geo,
//       });
//       return true;
//     };

//     for (let z = -maxDist; z < maxDist; z += step)
//       for (let x = -maxDist; x < maxDist; x += step) {
//         let dist = Math.sqrt(x * x + z * z);
//         if (brandom(1 - density)) continue;
//         if (Math.abs(x) < 2 && Math.abs(z) < 2) continue;

//         let h = random(0.2, 0.5) * height;
//         let y = random(yStart, yStart + height - h);

//         for (let i = 0; i < 4; i++) {
//           if (brandom(0.75)) continue;

//           let a = ((PI * 2) / 4) * i;
//           let xOff = (step / 2) * Math.cos(a);
//           let zOff = (step / 2) * Math.sin(a);

//           let valid = false;
//           let count = 0;
//           do {
//             let fat = brandom(0.05)// && dist > 10
//             let w = fat ? random() : random(0.005, 0.02);
//             valid = addLine(
//               new Vector3(x + xOff, y, z + zOff),
//               new Vector3(x + xOff, h + y, z + zOff),
//               brandom() ? 0 : PI / 2,
//               w,
//               false,
//               fat
//             );
//             count++;
//           } while (!valid && count < 20);
//           //   if (count > 1) console.log(count-1)
//         }

//         for (let i = 0; i < 8; i++) {
//           let valid = false;
//           let count = 0;
//           do {
//             let xOff = brandom() ? 0 : Math.floor(random(1, 5));
//             let zOff = xOff === 0 ? Math.floor(random(1, 5)) : 0;
//             let fat = brandom(0.05)// && dist > 10
//             let w = fat ? random() : random(0.005, 0.02);

//             let y = random(yStart, yStart + height);

//             valid = addLine(
//               new Vector3(x, y, z),
//               new Vector3(x + xOff, y, z + zOff),
//               xOff === 0 ? 0 : PI / 2,
//               w,
//               true, 
//               fat
//             );
//             count++;
//           } while (!valid && count < 20);
//           // if (count > 1) console.log('.', count-1)
//         }
//       }
//   }

//   createGroundBoxes() {
//     const r = 50*2;
//     const step = 2;
//     const mat = new MeshBasicMaterial({ color: "white" });

//     for (let z = -r; z < r; z += step)
//       for (let x = -r; x < r; x += step) {
//         const boxR = 0.75;
//         const box = new BoxGeometry(boxR, 30, boxR);
//         const xOffset = random(-step / 2, step / 2);
//         const yOffset = random(-1, 1);
//         const zOffset = random(-step / 2, step / 2);
//         box.translate(x + xOffset, -15 + yOffset, z + zOffset);
//         const mesh = new Mesh(box, mat);
//         this.group.add(mesh);
//       }

//     const box = new BoxGeometry(5, 30, 5);
//     box.translate(0, -15, 0);
//     this.group.add(new Mesh(box, mat));
//   }
}