import { CatmullRomCurve3, Clock, Spherical, Vector3 } from "three"
import { settings } from "./gui"
import { camera, controls } from "./script"
import { interceptCircleLineSeg, random } from "./utils"

const pi = Math.PI
export let animationClock
let cameraSpline
let controlSpline

export function startCameraAnimation() {
    initCameraPath()
    animationClock = new Clock()
}

export function stopCameraAnimation() {
    animationClock = null
}

export function animateCamera() {
    if (!animationClock) return

    const elapsedTime = animationClock.getElapsedTime()

    if (elapsedTime > settings.loopDuration) {
        stopCameraAnimation()
    } else {
        camera.position.copy(cameraSpline.getPoint(elapsedTime/settings.loopDuration))

        if (settings.centerOffset > 0)
            controls.target.copy(controlSpline.getPoint(elapsedTime/settings.loopDuration))
    }
}

function initCameraPath() {
    const points = []
    const startPos = new Spherical().setFromVector3(camera.position)

    const currentPos = startPos.clone()
    const lastPos = startPos.clone()

    const minR = settings.minCameraDistance
    const maxR = settings.maxCameraDistance

    points.push(camera.position.clone())

    for (let i = 0 ; i < settings.viewPoints; i++) {
        let lp, cp, j = 0
        do {
            currentPos.radius = random(minR, maxR)
            currentPos.phi = (lastPos.phi + pi/4 + random(-1, 1) * pi/2*0.1)%(pi/2) // random(pi/2)
            currentPos.theta = lastPos.theta + pi + random(-1, 1) * pi/2 // random(pi*2)

            if (i === 0) {
                currentPos.theta = startPos.theta
            } else if (i === settings.viewPoints - 1) {
                currentPos.radius = maxR
                currentPos.phi = pi/2
            }

            lp = new Vector3().setFromSpherical(lastPos)
            cp = new Vector3().setFromSpherical(currentPos)

            if (j++ > 100) {
                console.log('ERROR: (100+ iterations) No valid viewpoint found for minR', minR)
                break
            } 
        } while(i > 0 && interceptCircleLineSeg(0, 0, 6, lp.x, lp.z, cp.x, cp.z).length > 0)

        points.push(new Vector3().setFromSpherical(currentPos))
        lastPos.copy(currentPos)
    }

    cameraSpline = new CatmullRomCurve3(points)

    const controlStart = controls.target.clone()
    const controlPoints = [controlStart]
    const r = settings.centerOffset
    for (let i = 0 ; i < settings.viewPoints-1; i++) {
        const x = random(-r, r)
        const y = controlStart.y + random(-r, r)
        const z = random(-r, r)
        const newPoint = new Vector3(x, y, z)
        controlPoints.push(newPoint)
    }
    controlPoints.push(controlStart)
    controlSpline = new CatmullRomCurve3(controlPoints)
}