import { Color, GLSL1, GLSL3, ShaderMaterial, Vector2 } from "three";
import { color_settings } from "./palettes";
import { isWebgl2, renderer, scene } from "./script";

export const customShaderMaterial = (props) => isWebgl2 ?
new ShaderMaterial( {
    transparent: true,
    glslVersion: GLSL3,
    fog: true,
	uniforms: {
		time: { value: 1.0 },
		resolution: { value: new Vector2() },
        transparent: {value: props.transparent || props.transparent === undefined},
        bgColor: { value: new Color(color_settings.background).toArray() },
        invertY: { value: props.invertY },
        // noiseIntensity: { value: props.noiseIntensity },
        
        topColor:    { type: "c", value: new Color( 0x0077ff ) },
        bottomColor: { type: "c", value: new Color( 0xffffff ) },
        offset:      { type: "f", value: 33 },
        exponent:    { type: "f", value: 0.6 },
        fogColor:    { type: "c", value: scene.fog.color },
        fogNear:     { type: "f", value: scene.fog.near },
        fogFar:      { type: "f", value: scene.fog.far },
        fogDensity:  { type: "f", value: scene.fog.density}
	},
    ...props,
	vertexShader: `
    in vec3 colorA, colorB, colorC;
    in float direction;
    in float noiseIntensity;
    in float opacity;

    out vec2 vUv;
    out vec3 cols[3];
    // out vec3 pos;
    out float dir;
    out float noise;
    out float fOpacity;

    void main() {
        vUv = uv;
        cols = vec3[](colorA, colorB, colorC);
        dir = direction;
        noise = noiseIntensity;
        // pos = position;
        fOpacity = opacity;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
    `,
	fragmentShader: `
    uniform bool transparent;
    uniform bool invertY;
    uniform vec3 bgColor;

    uniform vec3 fogColor;
    uniform float fogNear;
    uniform float fogFar;
    uniform float fogDensity;
    // uniform float noiseIntensity;

    in vec2 vUv;
    in vec3 cols[3];
    // in vec3 pos;
    in float dir;
    in float noise;
    in float fOpacity;

    out vec4 outColor;

    float rand(vec2 co){
        return fract(sin(dot(co, vec2(12.9898, 78.233))) * 43758.5453);
    }

    float rand(vec3 co){
        return fract( sin(dot(rand(co.xy*23.425), rand(co.zz)*23.111)) );
    }

    void main() {
        float dst = pow(length(vUv)*0.75, 4.); // Top Right

        if (dir == 1.) dst = pow(length(vUv)*0.75, 4.) + pow(length(vec2(1.-vUv.x, vUv.y))*0.75, 4.); // Top Left+Right
        else if (dir == 2.) dst = pow(length(vUv)*0.75, 4.) + pow(length(vec2(1.-vUv.x, 1.-vUv.y))*0.75, 4.); // Billboards
        else if (dir == 3.) dst = vUv.y; // Vertical
        else if (dir == 4.) dst = sqrt(vUv.y);

        if (invertY) dst = .8 - dst;

        vec2 fuv = vUv;
        // vec3 fuv = floor(pos * 25.);
        dst += rand(fuv)*dst*noise;

        vec3 finalCol = vUv.y < 0.5 ? mix(cols[0], cols[1], vUv.y*2.) : mix(cols[1], cols[2], (vUv.y-0.5)*2.);

        outColor = transparent ? vec4(finalCol, dst) : vec4(mix(bgColor, finalCol, dst), 1.);

        #ifdef USE_FOG
          #ifdef USE_LOGDEPTHBUF_EXT
              float depth = gl_FragDepthEXT / gl_FragCoord.w;
          #else
              float depth = gl_FragCoord.z / gl_FragCoord.w;
          #endif

          #ifdef FOG_EXP2
            const float LOG2 = 1.442695;
            float fogFactor = exp2( - fogDensity * fogDensity * depth * depth * LOG2 );
            fogFactor = 1.0 - clamp( fogFactor, 0.0, 1.0 );
          #else
            float fogFactor = smoothstep( fogNear, fogFar, depth );
          #endif

          finalCol = mix(fogColor*1.+0.*vec3(1.,0.,0.), finalCol, fOpacity);
          outColor = vec4(mix( finalCol, fogColor, fogFactor ), dst);
      #endif
    }
    `,
} ) :
new ShaderMaterial( {
    transparent: true,
    glslVersion: GLSL1,
    fog: true,
	uniforms: {
		time: { value: 1.0 },
		resolution: { value: new Vector2() },
        transparent: {value: props.transparent || props.transparent === undefined},
        bgColor: { value: new Color(color_settings.background).toArray() },
        invertY: { value: props.invertY },
        // noiseIntensity: { value: props.noiseIntensity },

        topColor:    { type: "c", value: new Color( 0x0077ff ) },
        bottomColor: { type: "c", value: new Color( 0xffffff ) },
        offset:      { type: "f", value: 33 },
        exponent:    { type: "f", value: 0.6 },
        fogColor:    { type: "c", value: scene.fog.color },
        fogNear:     { type: "f", value: scene.fog.near },
        fogFar:      { type: "f", value: scene.fog.far },
        fogDensity:  { type: "f", value: scene.fog.density}
	},
    ...props,
	vertexShader: `
    attribute vec3 colorA, colorB, colorC;
    attribute float direction;
    attribute float noiseIntensity;
    attribute float opacity;

    varying vec2 vUv;
    // varying vec3 cols[3];
    varying vec3 colA, colB, colC;
    // out vec3 pos;
    varying float dir;
    varying float noise;
    varying float fOpacity;

    void main() {
        vUv = uv;
        // cols = vec3[3](colorA, colorB, colorC);
        colA = colorA;
        colB = colorB;
        colC = colorC;
        dir = direction;
        noise = noiseIntensity;
        // pos = position;
        fOpacity = opacity;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
    `,
	fragmentShader: `
    uniform bool transparent;
    uniform bool invertY;
    uniform vec3 bgColor;

    uniform vec3 fogColor;
    uniform float fogNear;
    uniform float fogFar;
    uniform float fogDensity;
    // uniform float noiseIntensity;

    varying vec2 vUv;
    // varying vec3 cols[3];
    varying vec3 colA, colB, colC;
    // in vec3 pos;
    varying float dir;
    varying float noise;
    varying float fOpacity;

    // out vec4 outColor;

    float rand(vec2 co){
        return fract(sin(dot(co, vec2(12.9898, 78.233))) * 43758.5453);
    }

    float rand(vec3 co){
        return fract( sin(dot(rand(co.xy*23.425), rand(co.zz)*23.111)) );
    }

    void main() {
        float dst = pow(length(vUv)*0.75, 4.); // Top Right

        if (dir == 1.) dst = pow(length(vUv)*0.75, 4.) + pow(length(vec2(1.-vUv.x, vUv.y))*0.75, 4.); // Top Left+Right
        else if (dir == 2.) dst = pow(length(vUv)*0.75, 4.) + pow(length(vec2(1.-vUv.x, 1.-vUv.y))*0.75, 4.); // Billboards
        else if (dir == 3.) dst = vUv.y; // Vertical
        else if (dir == 4.) dst = sqrt(vUv.y);

        if (invertY) dst = .8 - dst;

        vec2 fuv = vUv;
        // vec3 fuv = floor(pos * 25.);
        dst += rand(fuv)*dst*noise;

        vec3 finalCol = vUv.y < 0.5 ? mix(colA, colB, vUv.y*2.) : mix(colB, colC, (vUv.y-0.5)*2.);

        gl_FragColor = transparent ? vec4(finalCol, dst) : vec4(mix(bgColor, finalCol, dst), 1.);
        
        #ifdef USE_FOG
          #ifdef USE_LOGDEPTHBUF_EXT
              float depth = gl_FragDepthEXT / gl_FragCoord.w;
          #else
              float depth = gl_FragCoord.z / gl_FragCoord.w;
          #endif

          #ifdef FOG_EXP2
            const float LOG2 = 1.442695;
            float fogFactor = exp2( - fogDensity * fogDensity * depth * depth * LOG2 );
            fogFactor = 1.0 - clamp( fogFactor, 0.0, 1.0 );
          #else
            float fogFactor = smoothstep( fogNear, fogFar, depth );
          #endif

          finalCol = mix(fogColor*1.+0.*vec3(1.,0.,0.), finalCol, fOpacity);
          gl_FragColor = vec4(mix( finalCol, fogColor, fogFactor ), dst);
      #endif
    }
    `,
} );