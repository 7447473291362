import dat from 'dat.gui'
import { Cylindrical } from 'three'
import { startCameraAnimation, stopCameraAnimation } from './animations'
import { demo, forceSettings } from './demo'
import { colorGUI, color_settings, initPalettes, randomPalette, updateColors } from './palettes'
import { camera, city, saveAsImage, scene, updateSize } from './script'
import { map, random, randomArr } from './utils'

export let gui

export const settings = {
    city_buildings: 40,
    city_spacing: 0.25,
    city_height: 14,
    city_height_decrease: 5.5,
    camera_fov: 90,
    top_bending: 3,
    bottom_bending: 0.5,

    fog_type: 0,
    fog_dist_start: 0,
    fog_dist_range: 15,
    fog_density: 1,//0.05,
    environment_density: 0.35,
    star_count: 1500,

    panel_noise_density: 0,

    mini_density: 0.3, // Top mini density
    mini_different_type_density: 0.2, // Top mini type different from the main building type

    noiseResolution: 20,
    noiseScale: 0.5,
    noiseIntensity: 0.75,

    width: 1000,
    height: 1000,
    savePixelRatio: 1,
    save: saveAsImage,

    loopDuration: 20,
    viewPoints: 5,
    minCameraDistance: 20,
    maxCameraDistance: 30,
    centerOffset: 0,
    animateCity: startCameraAnimation,
    stopCameraAnimation: stopCameraAnimation,

    generate: generate,
    randomize: () => {randomizeParameters(), randomPalette()},
    loadLatestSettings: loadLatestSettings,
}

export const b_settings = {
    type_rectangle_density: 1/3,
    type_cylinder_density: 1/3,
    type_triangle_density: 1/3,
    stroke_width: 0.02,

    horizontal_lines_density: 1,
    vertical_lines_density: 1,
    horizontal_overflow_density: 0.1,
    vertical_overflow_density: 0.1,
    transparent_grid_density: 0.2,
    transparent_gradient_density: 0.2,
    gradient_style_density: 0.5,
    facades_density: 0.75,
    wavy_density: 0.5,//0.35

    cylindric_type_density: 0.5,
    minimum_radius: 0.35,
    billboards_density: 0.4,
    floating_facades_density: 0.4,
    transparent_cylinders_density: 0,
    cylindric_lines_height: 1,
    cylindric_lines_stepY: 0.04,

    triangle_ring_density: 0.8,
    triangle_window_density: 0.4,
}

let generalFolder, environmentFolder
let rectangleFolder, cylinderFolder, triangleFolder
let wavesFolder, cityFolder, animationFolder
export function initGUI() {
    if (!demo) {
        gui = new dat.GUI()
        gui.add(settings, 'generate').name('Generate New')
        gui.add(settings, 'randomize').name('Randomize Parameters')
        gui.add(settings, 'loadLatestSettings').name('Load Previous')

        animationFolder = gui.addFolder('Animation')
        cityFolder = gui.addFolder('City')
        environmentFolder = gui.addFolder('Environment')
        rectangleFolder = gui.addFolder('Rectangles')
        wavesFolder = gui.addFolder('Waves')
        cylinderFolder = gui.addFolder('Cylinders')
        triangleFolder = gui.addFolder('Triangles')
        generalFolder = gui.addFolder('Capture')

        animationFolder.open()
        cityFolder.open()

        animationFolder.add(settings, 'loopDuration', 1, 60, 1).name('Anim Duration')
        animationFolder.add(settings, 'viewPoints', 2, 20, 1).name('View Points')
        animationFolder.add(settings, 'minCameraDistance', 6, 30, 1).name('Cam Min Dist')
        animationFolder.add(settings, 'maxCameraDistance', 20, 50, 1).name('Cam Max Dist')
        animationFolder.add(settings, 'centerOffset').name('Center Offset')
        animationFolder.add(settings, 'stopCameraAnimation').name('Stop Animation')
        animationFolder.add(settings, 'animateCity').name('Animate City')

        cityFolder.add(settings, 'city_buildings', 1, 100, 0.01).onChange(()=>city.init()).name('Building Count')
        cityFolder.add(settings, 'city_spacing', 0, 2, 0.01).onChange(()=>city.init()).name('Building Spacing')
        // cityFolder.add(settings, 'city_height', 1, 20, 0.01).onChange(()=>city.init()).name('Global Height')
        // cityFolder.add(settings, 'city_height_decrease', 1, 20, 0.01).onChange(()=>city.init()).name('Edges Height')
        // cityFolder.add(settings, 'camera_fov', 5, 120, 1).onChange(updateCameraFov).name('Camera FOV')
        cityFolder.add(settings, 'top_bending', 0.5, 5, 0.01).onChange(()=>city.init()).name('Top Bending')
        cityFolder.add(settings, 'bottom_bending', 0, 1, 0.01).onChange(()=>city.init()).name('Bottom Bending')
        cityFolder.add(settings, 'mini_density', 0, 1, 0.01).onChange(()=>city.init()).name('Mini Density')
        cityFolder.add(settings, 'mini_different_type_density', 0, 1, 0.01).onChange(()=>city.init()).name('Different Mini Shape Density')
        // cityFolder.add(settings, 'fog_type', 0, 1, 1).onChange(updateFog).name('Fog Type')
        environmentFolder.add(settings, 'fog_density', 0, 2, 0.001).onChange((d) => scene.fog.density = getFogIntesityFromFov() * d).name('Fog Density')
        // cityFolder.add(settings, 'fog_dist_start', 0, 10, 0.001).onChange((d) => scene.fog.near = d).name('Fog2 Start')
        // cityFolder.add(settings, 'fog_dist_range', 0, 25, 0.001).onChange((d) => scene.fog.far = settings.fog_dist_start + d).name('Fog2 Range')
        environmentFolder.add(settings, 'star_count', 0, 5000, 1).onChange(()=>city.init()).name('Star Count')
        environmentFolder.add(settings, 'environment_density', 0, 1, 0.001).onChange(()=>city.init()).name('Env. Density')
        // cityFolder.add(settings, 'panel_noise_density', 0, 1, 0.001).onChange(()=>city.init()).name('Noise Panel Dens.')
        generalFolder.add(settings, 'width', 0, 4000, 1).onChange(updateSizes).name('Width')
        generalFolder.add(settings, 'height', 0, 4000, 1).onChange(updateSizes).name('Height')
        generalFolder.add(settings, 'savePixelRatio', 1, 2, 1).name('Resolution')
        generalFolder.add(settings, 'save').name('Save image')
        // cityFolder.add(b_settings, 'building_type_density', 0, 1, 0.001).onChange(()=>city.init()).name('Rectangle/Cylindric')
        cityFolder.add(b_settings, 'type_rectangle_density', 0, 1, 0.001).onChange(()=>city.init()).name('Rectangle Density')
        cityFolder.add(b_settings, 'type_cylinder_density', 0, 1, 0.001).onChange(()=>city.init()).name('Cylinder Density')
        cityFolder.add(b_settings, 'type_triangle_density', 0, 1, 0.001).onChange(()=>city.init()).name('Triangle Density')

        wavesFolder.add(settings, 'noiseScale', 0.001, 0.6, 0.001).onChange(()=>city.init()).name('Waves Scale')
        wavesFolder.add(settings, 'noiseIntensity', 0, 2, 0.001).onChange(()=>city.init()).name('Waves Intensity')
        wavesFolder.add(settings, 'noiseResolution', 1, 30, 1).onChange(()=>city.init()).name('Waves Resolution')
        
        rectangleFolder.add(b_settings, 'horizontal_lines_density', 0, 1, 0.001).onChange(()=>city.init()).name('Horizontal Lines Density')
        rectangleFolder.add(b_settings, 'vertical_lines_density', 0, 1, 0.001).onChange(()=>city.init()).name('Vertical Lines Density')
        rectangleFolder.add(b_settings, 'horizontal_overflow_density', 0, 1, 0.01).onChange(()=>city.init()).name('Horizontal Overflow Density')
        rectangleFolder.add(b_settings, 'vertical_overflow_density', 0, 1, 0.01).onChange(()=>city.init()).name('Vertical Overflow Density')
        rectangleFolder.add(b_settings, 'transparent_grid_density', 0, 1, 0.01).onChange(()=>city.init()).name('Transparent Grid Density')
        rectangleFolder.add(b_settings, 'transparent_gradient_density', 0, 1, 0.01).onChange(()=>city.init()).name('Transparent Gradient Density')
        rectangleFolder.add(b_settings, 'gradient_style_density', 0, 1, 0.01).onChange(()=>city.init()).name('Grid/Gradient Style Density')
        rectangleFolder.add(b_settings, 'facades_density', 0, 1, 0.01).onChange(()=>city.init()).name('Floating Facades Density')
        rectangleFolder.add(b_settings, 'stroke_width', 0.005, 0.1, 0.001).onChange(()=>city.init()).name('Stroke Width')
        rectangleFolder.add(b_settings, 'wavy_density', 0, 1, 0.001).onChange(()=>city.init()).name('Wavy Density')

        cylinderFolder.add(b_settings, 'cylindric_type_density', 0, 1, 0.001).onChange(()=>city.init()).name('Type 1 / Type 2')
        cylinderFolder.add(b_settings, 'minimum_radius', 0.15, 0.5, 0.001).onChange(()=>city.init()).name('Minimum Radius')
        cylinderFolder.add(b_settings, 'billboards_density', 0, 1, 0.001).onChange(()=>city.init()).name('Billobards Density')
        cylinderFolder.add(b_settings, 'floating_facades_density', 0, 1, 0.001).onChange(()=>city.init()).name('Floating Facades Density')
        cylinderFolder.add(b_settings, 'transparent_cylinders_density', 0, 1, 0.001).onChange(()=>city.init()).name('Transparent Density')
        cylinderFolder.add(b_settings, 'cylindric_lines_stepY', 0.01, 0.2, 0.001).onChange(()=>city.init()).name('Lines Density')
        cylinderFolder.add(b_settings, 'cylindric_lines_height', 0, 2, 0.001).onChange(()=>city.init()).name('Stroke Width')

        triangleFolder.add(b_settings, 'triangle_ring_density', 0, 1, 0.001).onChange(()=>city.init()).name('Ring Density')
        triangleFolder.add(b_settings, 'triangle_window_density', 0, 1, 0.001).onChange(()=>city.init()).name('Window Density')
    }

    randomizeParameters()
    initPalettes()
}

function randomizeParameters() {
    if (demo) {
        forceSettings()
        return
    }
    
    settings.city_buildings = randomArr([15, 22, 29, 36, 43, 50, 57, 64, 71, 78, 85, 92, 99])
    settings.city_spacing = randomArr([0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7])
    // settings.top_bending = randomArr([1.8, 2, 2.2, 2.6, 2.8])
    // settings.bottom_bending = randomArr([0.4, 0.45, 0.5, 0.55, 0.6])
    settings.mini_density = randomArr([0.6, 0.65, 0.7, 0.75, 0.8])
    settings.mini_different_type_density = randomArr([0.35, 0.45, 0.5, 0.55, 0.65])

    b_settings.type_rectangle_density = random(0.233, 0.633)
    b_settings.type_cylinder_density = random(0.233, 0.633)
    b_settings.type_triangle_density = random(0.233, 0.633)

    settings.fog_density = random(0.46, 0.52)
    settings.star_count = random(550, 850)
    settings.environment_density = random(0.12, 0.24)

    b_settings.horizontal_lines_density = random(0.6, 1)
    b_settings.vertical_lines_density = random(0.6, 1)
    b_settings.horizontal_overflow_density = random(0.1, 0.25)
    b_settings.vertical_overflow_density = random(0.1, 0.45)
    b_settings.transparent_grid_density = random(0.1, 0.45)
    b_settings.transparent_gradient_density = random(0, 0.3)
    b_settings.gradient_style_density = random(0.2, 0.6)
    b_settings.facades_density = random(0.6, 0.9)
    b_settings.stroke_width = random(0.015, 0.030)

    b_settings.cylindric_type_density = random(0.4, 0.6)
    b_settings.minimum_radius = random(0.2, 0.5)
    b_settings.billboards_density = random(0.5, 1)
    b_settings.floating_facades_density = random(0.4, 0.8)
    b_settings.transparent_cylinders_density = random(0, 0.1)
    b_settings.cylindric_lines_stepY = random(0.05, 0.1)
    b_settings.cylindric_lines_height = random(0.65, 1.5)

    b_settings.triangle_ring_density = random(0.65, 0.95)
    b_settings.triangle_window_density = random(0.5, 0.9)

    // settings.city_buildings = 50
    // b_settings.type_rectangle_density = 0
    // b_settings.type_cylinder_density = 0
    // b_settings.type_triangle_density = 1

    if (gui) gui.updateDisplay()
}

function updateCameraFov() {
    let init_depht_s = Math.tan(90/2.0 * Math.PI/180.0) * 2.0;
    let current_depht_s = Math.tan(settings.camera_fov/2.0 * Math.PI/180.0) * 2.0;

    camera.position.setFromCylindrical(new Cylindrical(8 * init_depht_s / current_depht_s, Math.PI/4, 3))
    camera.fov = settings.camera_fov;
    scene.fog.density = map(settings.camera_fov, 30, 90, 0.02, 0.07) * settings.fog_density
    camera.updateProjectionMatrix();
}

export function getFogIntesityFromFov() {
    return map(settings.camera_fov, 30, 90, 0.02, 0.07)
}

function updateSizes() {
    updateSize(settings.width, settings.height)
}

function generate() {
    city.init(true)
    if (gui) gui.updateDisplay()
}

export function loadLatestSettings() {
    if (!localStorage.getItem('settings') || !localStorage.getItem('b_settings') || !localStorage.getItem('color_settings')) {
        alert('No previous saved settings found')
        return
    }
    
    const savedSettings = JSON.parse(localStorage.getItem('settings'))
    const savedBSettings = JSON.parse(localStorage.getItem('b_settings'))
    const savedColorSettings = JSON.parse(localStorage.getItem('color_settings'))

    for (let elm in savedSettings) settings[elm] = savedSettings[elm]
    for (let elm in savedBSettings) b_settings[elm] = savedBSettings[elm]
    for (let elm in savedColorSettings) color_settings[elm] = savedColorSettings[elm]

    city.seed = Number(localStorage.getItem('seed'))
    updateColors(true)

    if (!demo) {
        gui.updateDisplay()
        colorGUI.updateDisplay()
    }
}