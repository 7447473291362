import { Box3, DoubleSide, Group, Mesh, MeshBasicMaterial, Vector3 } from "three";
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { Building } from "./building";
import { demo, forceSeed } from "./demo";
import { Environment } from "./environment";
import { b_settings, settings } from "./gui";
import { color_settings } from "./palettes";
import { controls, scene } from "./script";
import { customShaderMaterial } from "./shader_material";
import { brandom, easeInOutCubic, frandom, map, PI, random, randomFromWeightedArray, setRandomSeed, updatePerformance } from "./utils";

export default class City {
    constructor() {
        this.seed = demo && forceSeed ? forceSeed : Math.random()*Number.MAX_SAFE_INTEGER
        setRandomSeed(this.seed)
        this.group = new Group()
        this.env = new Environment()
    }

    init(resetSeed=false) {
        updatePerformance('City init')
        if (resetSeed) this.seed = Math.random()*Number.MAX_SAFE_INTEGER
        setRandomSeed(this.seed)

        if (!demo && this.buildings) {
            localStorage.setItem('settings', JSON.stringify(settings))
            localStorage.setItem('b_settings', JSON.stringify(b_settings))
            localStorage.setItem('color_settings', JSON.stringify(color_settings))
            localStorage.setItem('seed', this.seed)
        }

        if (this.groundMesh) {
            this.groundMesh.clear()
            this.groundMesh.parent.remove(this.groundMesh)
        }
        if (this.group) {
            this.group.clear()
            if (this.group.parent) {
                this.group.parent.remove(this.group)
            }
        }
        scene.add(this.group)

        this.start()
        console.log(this)
    }

    start() {
        this.buildings = []

        this.meshes = {}
        this.currentBuilding = 0
        
        this.materials = {
            default: new MeshBasicMaterial({vertexColors: true}),//, side_: DoubleSide}),
            gradient: customShaderMaterial({side: DoubleSide}),
            opaqueGradient: customShaderMaterial({transparent: false, side: DoubleSide}),
            tetrahedron: customShaderMaterial({}),//{side_: DoubleSide}),
            polygon: new MeshBasicMaterial({vertexColors: true})
        }

        const aabb = new Box3()
        aabb.setFromObject( this.group )
        this.boundingBox = aabb
        // const boxHelper = new Box3Helper(aabb)
        // scene.add(boxHelper)

        this.env.init()

        let city_size = new Vector3()
        city_size = aabb.getSize(city_size)

        const max_distance = Math.max(city_size.x, city_size.z)/2
        controls.maxDistance = 20//demo ? max_distance*0.75 : max_distance    

        controls.enabled = false
    }

    generateNext() {
        // updatePerformance()

        const latest = this.buildings.length
        this.createNewCityBuilding()
        this.updateMeshes(latest)
        this.currentBuilding++

        // updatePerformance('Generate building ')

        if (this.currentBuilding === settings.city_buildings-1) {
            this.finish()
        }
    }

    finish() {
        controls.enabled = true
        console.log('scene children', scene.children)
        console.log('group', this.group)
    }

    createCity() {
        const addBuilding = (building, pos) => {
            building.base.translate(pos)
            // const baseHelper = new Box3Helper(building.base, 'green')
            // scene.add(baseHelper)

            building.pos.copy(pos)
            this.buildings.push(building)
        }

        const checkCollision = (newBase) => {
            const nbase = newBase.clone().expandByVector(new Vector3(1, 0, 1).multiplyScalar(settings.city_spacing))
            for (let {base} of this.buildings) 
                if (nbase.intersectsBox(base))
                    return true
            return false
        }

        for (let i = 0; i < settings.city_buildings; i++) {
            let radius = 0

            const building = this.createNewBuilding(i)
            const buildingPos = new Vector3(0, random(1), 0)
            let valid = false

            do {
                const angleResolution = 12
                const startAngle = random(PI*2)
                let angle

                for (let i = 0; i < angleResolution; i++) {
                    angle = startAngle + i/angleResolution*PI*2

                    buildingPos.x = radius * Math.cos(angle)
                    buildingPos.z = radius * Math.sin(angle)

                    const base = building.base.clone().translate(buildingPos)
                    valid = !checkCollision(base)

                    // console.log({buildings: this.buildings.length, radius, angle, valid})

                    if (valid) break
                }
                radius += 0.2
            } while (!valid)

            // console.log('Final', {radius, buildingX, buildingY, buildingZ})
            
            addBuilding(building, buildingPos)

            if (brandom(settings.mini_density)) {
                const type = brandom(settings.mini_different_type_density) ? frandom(3) : building.type
                const miniBuilding = new Building(new Vector3(0, 0, 0), new Vector3(building.w, 2, building.l), type, true)
                const miniBuildingPos = buildingPos.clone().add(new Vector3(0, building.h + 2.5, 0))

                addBuilding(miniBuilding, miniBuildingPos)
            }
        }
    }

    createNewCityBuilding() {
        const addBuilding = (building, pos) => {
            building.base.translate(pos)
            building.pos.copy(pos)
            this.buildings.push(building)
        }

        const checkCollision = (newBase) => {
            const nbase = newBase.clone().expandByVector(new Vector3(1, 0, 1).multiplyScalar(settings.city_spacing))
            for (let {base} of this.buildings) 
                if (nbase.intersectsBox(base))
                    return true
            return false
        }

        let radius = 0

        const building = this.createNewBuilding(this.currentBuilding)
        const buildingPos = new Vector3(0, random(1), 0)
        let valid = false

        do {
            const angleResolution = 12
            const startAngle = random(PI*2)
            let angle

            for (let i = 0; i < angleResolution; i++) {
                angle = startAngle + i/angleResolution*PI*2

                buildingPos.x = radius * Math.cos(angle)
                buildingPos.z = radius * Math.sin(angle)

                const base = building.base.clone().translate(buildingPos)
                valid = !checkCollision(base)

                // console.log({buildings: this.buildings.length, radius, angle, valid})

                if (valid) break
            }
            radius += 0.2
        } while (!valid)

        // console.log('Final', {radius, buildingX, buildingY, buildingZ})
        
        addBuilding(building, buildingPos)

        if (brandom(settings.mini_density)) {
            const type = brandom(settings.mini_different_type_density) ? frandom(3) : building.type
            const miniBuilding = new Building(new Vector3(0, 0, 0), new Vector3(building.w, 2, building.l), type, true)
            const miniBuildingPos = buildingPos.clone().add(new Vector3(0, building.h + 2.5, 0))

            addBuilding(miniBuilding, miniBuildingPos)
        }
    }

    createNewBuilding(buildingID) {
        const randomBuildingType = () => {
            let tot = b_settings.type_rectangle_density + b_settings.type_cylinder_density + b_settings.type_triangle_density
            let arr = [
                { type: 0, freq: b_settings.type_rectangle_density/tot },
                { type: 1, freq: b_settings.type_cylinder_density/tot },
                { type: 3, freq: b_settings.type_triangle_density/tot },
            ]

            let type = randomFromWeightedArray(arr).type
            if (type === 1 && brandom(b_settings.cylindric_type_density)) type = 2

            return type
        }

        const type = randomBuildingType()
        const w = 0.2 * frandom(4, 7)
        const l = brandom() ? w : w * random(0.5, 2)
        const h = buildingID === 0 ? 
            settings.city_height*1.2 
            : 
            Math.max(2, map(buildingID, 0, settings.city_buildings * 0.7, settings.city_height, 2, easeInOutCubic, true))

        const building = new Building(new Vector3(0, 0, 0), new Vector3(w, h, l), type, false)
        return building
    }

    updateMeshes(latest) {
        let groups = {}

        for (let i = latest; i < this.buildings.length; i++) {
            const b = this.buildings[i]

            for (let obj in b.shapeInstances) {
                if (!groups[obj]) groups[obj] = []
                b.shapeInstances[obj].forEach(geometry => geometry.translate(b.pos.x, b.pos.y, b.pos.z))
                groups[obj].push(...b.shapeInstances[obj])
            }
        }

        for (let groupName in groups) {
            const elements = groups[groupName]
            elements.forEach(e => bendGeometry(e))

            const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(elements, false)
            const mesh = new Mesh(mergedGeometry, this.materials[groupName])

            this.group.add(mesh)

            // if (this.meshes[groupName]) {
            //     const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(elements, false)
            //     const mesh = new Mesh(mergedGeometry, this.materials[groupName])

            //     this.group.add(mesh)
            //     // const mesh = this.meshes[groupName]
            //     // const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries([mesh.geometry, ...elements], false)
                
            //     // mesh.geometry = mergedGeometry
            // } else {
            //     const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(elements, false)
            //     const mesh = new Mesh(mergedGeometry, this.materials[groupName])

            //     this.meshes[groupName] = mesh

            //     this.group.add(mesh)
            // }
        }
    }

    createMeshes() {
        let groups = {}

        for (let b of this.buildings) {
            for (let obj in b.shapeInstances) {
                if (!groups[obj]) groups[obj] = []
                b.shapeInstances[obj].forEach(geometry => geometry.translate(b.pos.x, b.pos.y, b.pos.z))
                groups[obj].push(...b.shapeInstances[obj])
            }
        }

        let materials = {
            default: new MeshBasicMaterial({vertexColors: true, side_: DoubleSide}),
            gradient: customShaderMaterial({side: DoubleSide}),
            opaqueGradient: customShaderMaterial({transparent: false, side: DoubleSide}),
            tetrahedron: customShaderMaterial({side_: DoubleSide}),
            polygon: new MeshBasicMaterial({vertexColors: true})
        }

        this.meshes = {}

        for (let groupName in groups) {
            const elements = groups[groupName]

            elements.forEach(e => bendGeometry(e))
            const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(elements, false)
            const mesh = new Mesh(mergedGeometry, materials[groupName])

            this.meshes[groupName] = mesh

            this.group.add(mesh)

            // this.meshes.push([
            //     mesh, 
            //     [...mesh.geometry.attributes.position.array]
            // ])
        }

        // console.log('meshes', groups)

        scene.add(this.group)
    }
}

export function bendGeometry(geometry) {
    let buffer = geometry.attributes.position.array

    for (let i = 0; i <= buffer.length-3; i+=3) {
        // let pos = new Vector3(buffer[i], buffer[i+1], buffer[i+2])
    
        // let bend = map(pos.y, 0, settings.top_bending, 1-settings.bottom_bending, 1)
        // pos.multiply(new Vector3(bend, 1, bend))

        // buffer[i] = pos.x;
        // buffer[i+1] = pos.y;
        // buffer[i+2] = pos.z;

        const bend = buffer[i+1] / settings.top_bending * settings.bottom_bending + (1-settings.bottom_bending)

        buffer[i] *= bend
        buffer[i+2] *= bend
    }
}