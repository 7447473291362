import { Box3, BufferAttribute, Color, CylinderGeometry, ExtrudeGeometry, Group, Int32BufferAttribute, PlaneGeometry, RingGeometry, Shape, Vector2, Vector3 } from "three";
import { b_settings, settings } from "./gui";
import { color_settings, getColorFromGradient, getGradient, getInterpolatedColorFromGradient } from "./palettes";
import { brandom, createColorAttribute, easeInOutQuint, easeRandom, frandom, map, noise, PI, random, randomArr } from "./utils";

export class Building {
    constructor(pos, size, type, mini=false) {
        // Position
        this.pos = pos
        this.minX = this.minY = this.minZ = Infinity
        this.maxX = this.maxY = this.maxZ = -Infinity

        // Dimensions
        this.size = size
        this.w = size.x
        this.h = size.y
        this.l = size.z

        this.mini = mini

        // Building Main Group
        this.group = new Group()
        this.uuid = random(Number.MAX_SAFE_INTEGER)
        this.type = type
        this.shapeInstances = {}
        this.facades = []

        this.stroke_width = b_settings.stroke_width * random(0.8, 1.2)
        this.faceStyleFrequency = b_settings.gradient_style_density
        this.resolution = settings.noiseResolution

        this.noiseOffset = new Vector2(random(1000), random(1000))

        let a = [
            this.buildingRectangle,
            this.buildingCylinder,
            this.buildingCylinder2,
            this.buildingCone,
        ][type].bind(this)()

        this.calculateBase()
    }

    //////////// Utility ////////////

    addInstancedObject(geometry, color, group='default', direction=0, noiseIntensity=0) {
        const numVerts = geometry.getAttribute('position').count
        
        this.transformGeometryVertices(geometry)

        if (group === 'default' || group === 'polygon') {
            geometry.setAttribute('color', createColorAttribute(color, numVerts))
        } else if (group === 'gradient' || group === 'tetrahedron' || group === 'opaqueGradient') {
            function createDirectionAttribute() {
                // const directions = new Int8Array(numVerts).map(_ => direction)
                // return new Int32BufferAttribute(directions, 1)
                const directions = new Int16Array(numVerts).map(_ => direction)
                return new BufferAttribute(directions, 1, false)
            }
            // console.log(direction)

            geometry.setAttribute('colorA', createColorAttribute(color[0], numVerts))
            geometry.setAttribute('colorB', createColorAttribute(color[1], numVerts))
            geometry.setAttribute('colorC', createColorAttribute(color[2], numVerts))
            geometry.setAttribute('direction', createDirectionAttribute())
        } else {
            throw new Error(group + ' not implemented')
        }

        function createNoiseAttribute(intensity) {
            const arr = new Uint8Array(numVerts).map(_ => intensity)
            return new BufferAttribute(arr, 1, true)
        }

        function createOpacityAttribute() {
            const arr = new Uint8Array(numVerts).map(_ => 255)
            return new BufferAttribute(arr, 1, true)
        }

        geometry.setAttribute('noiseIntensity', createNoiseAttribute(brandom(settings.panel_noise_density) ? noiseIntensity*255 : 0))
        geometry.setAttribute('opacity', createOpacityAttribute())
        
        if (!this.shapeInstances[group]) this.shapeInstances[group] = []
        this.shapeInstances[group].push(geometry)
    }

    // Apply the bending effect
    transformGeometryVertices(geometry) {
        let buffer = geometry.attributes.position.array
        let noiseScale = settings.noiseScale*0.1

        if (this.wavy)
            for (let i = 0; i <= buffer.length-3; i+=3) {
                let pos = new Vector3(buffer[i], buffer[i+1], buffer[i+2])

                let noiseVal = 1.5 + noise.noise3D((pos.x + this.noiseOffset.x) * noiseScale, pos.y * noiseScale, (pos.z + this.noiseOffset.y) * noiseScale) * settings.noiseIntensity
                let offset = new Vector3(this.noiseOffset.x, 0, this.noiseOffset.y).multiplyScalar(0.003)
                pos.add(offset)
                pos.multiply(new Vector3(noiseVal, 1, noiseVal))
                pos.sub(offset)

                if (pos.x < this.minX) this.minX = pos.x
                if (pos.x > this.maxX) this.maxX = pos.x
                if (pos.y < this.minY) this.minY = pos.y
                if (pos.y > this.maxY) this.maxY = pos.y
                if (pos.z < this.minZ) this.minZ = pos.z
                if (pos.z > this.maxZ) this.maxZ = pos.z

                buffer[i] = pos.x;
                buffer[i+1] = pos.y;
                buffer[i+2] = pos.z;
            }
    }

    // Calculate ground bounding box
    calculateBase() {
        if (!this.wavy) {
            this.base = new Box3(
                new Vector3(-this.w/2, -this.h/2, -this.l/2), 
                new Vector3(this.w/2, this.h/2, this.l/2))
        } else {
            this.base = new Box3(
                new Vector3(this.minX, this.minY, this.minZ),
                new Vector3(this.maxX, this.maxY, this.maxZ))
        }

        return

        let arr = Object.values(this.shapeInstances)
        .flat(1)
        .map(i => new Array(...i.attributes.position.array))
        .flat(1)

        let minX, maxX, minY, maxY, minZ, maxZ
        let pos = new Vector3()

        for (let i = 0; i <= arr.length - 3; i += 3) {
            pos.set(...arr.slice(i, i+3))
            if (!minX || minX && pos.x < minX) minX = pos.x
            if (!maxX || maxX && pos.x > maxX) maxX = pos.x
            if (!minY || minY && pos.y < minY) minY = pos.y
            if (!maxY || maxY && pos.y > maxY) maxY = pos.y
            if (!minZ || minZ && pos.z < minZ) minZ = pos.z
            if (!maxZ || maxZ && pos.z > maxZ) maxZ = pos.z
        }

        // let b = new Box3(new Vector3(minX, minY, minZ), new Vector3(maxX, maxY, maxZ))
        // let h = new Box3Helper(b, 0xff0000)
        // scene.add(h)

        this.base = new Box3(new Vector3(minX, minY, minZ), new Vector3(maxX, maxY, maxZ))
    }

    //////////// Building Types ////////////

    // BUILDING TYPE 3
    buildingCone() {
        let stepY = this.h / (this.mini ? frandom(6, 10) : frandom(25, 35))
        let circleRes = randomArr([3, 5, 7])
        let angleOffset = {3: 1/6, 5: 2/6.5, 6: 2/3, 7:2/3}[circleRes]
        let sideSizeRatio = {3: 0.45, 5: random(0.2, 0.3), 6: random(0.2, 0.3), 7: random(0.2, 0.3)}[circleRes]
        let windowSpacingFromCenter = {3: 0.3, 5: 0.5, 6: 0.5, 7: 0.5}[circleRes]
        let innerR = this.w/2 * 0.8

        const polygonShape = new Shape()
        let radius = this.w/2 * 0.5
        for (let i = 0; i < circleRes; i++) {
            let a = i * PI*2/circleRes
            let x = radius * Math.cos(a)
            let y = radius * Math.sin(a)
            if (i === 0) polygonShape.moveTo(x, y)
            else polygonShape.lineTo(x, y)
        }
        const extrudedGeometry = new ExtrudeGeometry(polygonShape, {depth: this.h, bevelEnabled: false})
        extrudedGeometry.rotateX(-PI/2)
        extrudedGeometry.translate(0, 0.01, 0)
        this.addInstancedObject(extrudedGeometry, new Color(color_settings.background), 'polygon')

        for (let y = 0; y < this.h; y += stepY) {
            // Rings
            const geometry = new RingGeometry(map(y, 0, this.h, this.w/2*0.4, innerR, easeInOutQuint), this.w/2, circleRes)
            geometry.rotateX(-PI/2)
            geometry.translate(0, y, 0)

            if (brandom(b_settings.triangle_ring_density))
                this.addInstancedObject(geometry, getGradient('triangles', Math.floor(map(y, 0, this.h, 1, 6))), 'opaqueGradient', frandom(2))

            // Windows
            if ((y < this.h/2 && brandom(b_settings.triangle_window_density)) || (y > this.h/2 && brandom(b_settings.triangle_window_density*0.75)))
                for (let i = 0; i < circleRes; i++) {
                    let a = i/circleRes * PI * 2 + PI * angleOffset
                    const face = this.buildFace(0, y, 0, this.w*sideSizeRatio, stepY*0.9, this.w*windowSpacingFromCenter, a)
                    this.addInstancedObject(face, getGradient('triangles', Math.floor(map(y, 0, this.h, 1, 6))), 'gradient', frandom(2), 1)
                }
            // else y-=stepY/2
        }
    }

    buildingCylinder(billboards=true) {
        let total = 0
        let partsCount = frandom(3, 6)
        let sizes = new Array(partsCount).fill(0).map(_ => {
            let v = random(1, 1.5)
            total += v
            return v
        }).map(e => e/total)

        this.innerRadius = random(b_settings.minimum_radius, 0.5)

        // Main cylinder
        let stepY = b_settings.cylindric_lines_stepY
        let y = 0
        for (let i = 0; i < partsCount; i++) {
            const height = this.h * sizes[i] * random(0.5, 0.6)
            const minR = this.w * this.innerRadius
            const maxR = this.w * 0.5

            this.buildStyledCylinder(0, y, 0, minR, maxR, height, stepY, partsCount-i)

            if (brandom(b_settings.floating_facades_density) && i < partsCount-1)
                this.buildCylinderFacades(0, y+height, 0, minR, maxR, this.h * sizes[i]-height, 0.2)
            else if (brandom(b_settings.billboards_density) && billboards) {
                let totalH = this.h * sizes[i]-height
                let h_ = totalH * random(0.5, 0.7)  
                let w = 2*PI/frandom(3, 10)
                this.buildCylinderBillboards(0, y+height+(totalH-h_)/2, 0, minR, maxR, w*random(0.65, 0.8), h_, w)
            }

            y += this.h * sizes[i]
        }

        let cylinder = this.buildCylinder(0, 0, 0, this.w*this.innerRadius, this.h*0.9)
        if (!brandom(b_settings.transparent_cylinders_density)) this.addInstancedObject(cylinder, new Color(color_settings.background))
    }

    buildingCylinder2() {
        const stepY = 0.1
        const facades = []

        const isValid = (facade) => {
            for (let f of facades) {
                if (facade.y < f.y + f.h && facade.y + facade.h > f.y) {
                    if (!(f.startA + f.length > PI*2 && facade.startA < (f.startA + f.length)%(PI*2)) && 
                        (facade.startA > f.startA + f.length || facade.startA + facade.length < f.startA)) continue
                    else return false
                }                   
            }
            return true
        }

        const facadesCount = 25/2
        const ringCount = 20/2

        for (let i = 0; i < facadesCount; i++) {
            let y = this.h * random()
            if (y > 10) y = this.h * random(0.7, 1)
            let w = this.w/2 * random(0.8, 1)
            let h = (this.h-y) * (brandom() ? random(0.1, 1) : random(0.1, 0.5))
            let startA = random(PI*2)
            let length = random(PI/16, PI)

            let valid = isValid({y, h, startA, length})
            if (!valid) { i--; continue }
            facades.push({y, h, startA, length})

            const cylinder = this.buildArc(0, y, 0, w, h, startA, length)
            this.addInstancedObject(cylinder, getGradient('cylinders', Math.floor(map(i, 0, facadesCount, 1, 6))), 'gradient', frandom(2), 1)
        }

        for (let i = 0; i < ringCount; i++) {
            let y = this.h * easeRandom(0, 1) //this.h * random(0.3)
            let w = this.w*0.4 // this.w/2 * random(0.8, 1)
            let h = random(w)
            let startA = random(PI*2)
            let length = random(PI, PI*2)

            let valid = isValid({y, h, startA, length})
            if (!valid) { i--; continue }
            facades.push({y, h, startA, length})

            const cylinder = this.buildArc(0, y, 0, w, h, startA, length)
            this.addInstancedObject(cylinder, getGradient('cylinders', Math.floor(map(i, 0, ringCount, 1, 6))), 'gradient', frandom(2))
        }
        
        // Vertical gradient antennas 
        // const c = this.buildArc(0, this.h * random(0.8, 1), 0, this.w/2, this.h * random(0.4, 0.6), random(PI*2), 0.5)
        // this.addInstancedObject(c, getGradient('cylinders', 1), 'gradient', frandom(2))

        let h0 = random(0.2, 0.3) * this.h
        let stepY0 = h0/frandom(5, 8)
        let w0 = stepY0 * 10
        this.buildRepeatedStyledCylinder(0, random(0.7, 0.8) * this.h, 0, this.w/2, h0, stepY0, 6, w0)

        let h1 = random(0.05, 0.15) * this.h, step1 = frandom(2, 3)
        this.buildRepeatedStyledCylinder(0, random(0.4, 0.5) * this.h, 0, this.w/2, h1, h1/(step1-0.01), 6)
        
        let h2 = random(0.05, 0.15) * this.h, step2 = 5 - step1
        this.buildRepeatedStyledCylinder(0, random(0.1, 0.2) * this.h, 0, this.w/2, h2, h2/(step2-0.01), 6)

        let cylinder = this.buildCylinder(0, 0, 0, this.w*0.4, this.h*0.9)
        if (!brandom(b_settings.transparent_cylinders_density)) this.addInstancedObject(cylinder, new Color(color_settings.background))
    }

    // Builds styled a cylinder made of vertical and horizontal lines
    buildStyledCylinder(x, y, z, minR, maxR, h, stepY, partCount, rotation=0) {
        for (let j = y; j < y + h; j += stepY) {
            let y_ = j + random(-0.02, 0.02)
            let r = random(minR, maxR)
            let h_ = random(0.02, 0.04)*b_settings.cylindric_lines_height
            let startA = random(PI*2)
            let length = random(PI*2)
            
            const cylinder = this.buildRotatedArc(rotation, y_, r, h_, startA, length)
            this.addInstancedObject(cylinder, getColorFromGradient('cylinders', partCount, j, y, y+h))
        }
    }

    buildRepeatedStyledCylinder(x, y, z, w, h, stepY, partCount, heightMultiplier = 1) {
        let r = w
        let startA = random(PI*2)
        let length = random(PI, PI*2)
        
        for (let j = y; j < y + h; j += stepY) {
            let height = 0.04*b_settings.cylindric_lines_height * (brandom(0.5) ? 1 : random(1, heightMultiplier))
            const cylinder = this.buildArc(x, j, z, r, height, startA, length)
            this.addInstancedObject(cylinder, getColorFromGradient('cylinders', partCount, j, y, y+h))
        }
    }

    buildCylinderBillboards(x, y, z, minR, maxR, w, h, stepA) {
        let r = random(minR, maxR)
        for (let a = 0; a < PI*2; a += stepA) {
            const cylinder = this.buildArc(x, y, z, r, h, a, w)
            this.addInstancedObject(cylinder, getGradient('billboards'), 'gradient', 2)
        }
    }

    buildCylinderFacades(x, y, z, minR, maxR, h, stepY) {
        let minH = random(0.1, 0.3)
        let maxH = random(minH, 0.6)
        for (let j = y; j < y + h; j += stepY) {
            const r = random(minR, maxR + 0.1)
            let startA = random(PI*2)
            let length = random(PI*2)

            const cylinder = this.buildArc(x, j + random(-0.02, 0.02), z, r, random(minH, maxH), startA, length)
            this.addInstancedObject(cylinder, getGradient('facadesCylinders'), 'gradient', frandom(0, 1), 1)
        }
    }

    ////////

    buildingRectangle() {
        this.wavy = !this.mini && brandom(b_settings.wavy_density)

        let total = 0
        let partsCount = this.h < 2 ? 1 : frandom(2, 6)
        let sizes = new Array(partsCount).fill(0).map(_ => {
            let v = random(1, 1.5)
            total += v
            return v
        }).map(e => e/total)

        // const bgColor = new Color('#313131')
        const bgColor = new Color(color_settings.background)

        // let ratios = new Array(partsCount).fill(0).map((_,i) => map(i, 0, partsCount-1, 1, 0.5)).sort()
        let ratios = new Array(partsCount).fill(0).map(_ => 0.1*frandom(4, 10))
        
        // Rule : Top section must not be thinner than first section
        if (ratios[0] > ratios[partsCount-1]) ratios[partsCount-1] = 0.1*frandom(ratios[0]*10, 10)
        
        // Rule : Center section must be empty if the building has more than 3 sections && is not wavy
        let removeCenter = partsCount <= 3 && !this.wavy ? -1 : Math.round((partsCount-1)/2)

        let y = 0
        for (let i = 0; i < partsCount; i++) {
            let ratio = ratios[i]
            let h = this.h * sizes[i] * 0.95
            let gridStepX = this.w*ratio / frandom(10, 12)  
            let gridStepY = h / frandom(8, 12)
            let currentVerticalOverflowLineCount = 0
            
            if (brandom(0.2) && i > 0) {
                let ratio = random(1.05, 1.2)
                this.buildFacadeClosedRect(y, this.w * ratio, h * random(0.2, 0.4), this.l * ratio)
            }

            if (i === removeCenter) {
                y += h
                continue
            }

            // this.resetWavyCollisionBox()

            for (let j = 0; j < 4; j++) {
                let originalW = (j%2 === 0 ? this.w : this.l)
                let originalL = (j%2 === 0 ? this.l : this.w)
                let w = originalW * ratio
                let l = originalL * ratio
                let angle = PI/2*j

                if (brandom(this.faceStyleFrequency)) {
                    if (brandom(1-b_settings.transparent_gradient_density)) {
                        let bgFace = this.buildFace(0, y, -0.002, w, h, l, angle)
                        this.addInstancedObject(bgFace, bgColor)
                    }
                    let face =  this.buildFace(0, y, -0.001, w, h, l, angle)
                    this.addInstancedObject(face, getGradient('rectangles', partsCount-i), 'gradient')
                }
                else {
                    if (brandom(1-b_settings.transparent_grid_density)) {
                        let bgFace = this.buildFace(0, y, -0.002, w, h, l, angle)
                        this.addInstancedObject(bgFace, bgColor)
                    }

                    let verticalOverflow = (brandom(i === partsCount-1 ? b_settings.vertical_overflow_density*2 : b_settings.vertical_overflow_density) && !(j === 3 && currentVerticalOverflowLineCount > 0 && currentVerticalOverflowLineCount%2==1)) || 
                                            (j === 3 && currentVerticalOverflowLineCount > 0 && currentVerticalOverflowLineCount%2==0)
                    
                                            if (verticalOverflow) currentVerticalOverflowLineCount++
                    
                    // this.saveWavyCollisionBox()
                    this.buildGridFace(0, y, 0, w, h, l, gridStepX, gridStepY, angle, partsCount - i, true, verticalOverflow)
                    // this.restoreWavyCollisionBox()
                }

                if (brandom(b_settings.facades_density)) this.buildFacades(0, y, 0, w, h, l, angle)
            }
            
            // if (this.wavy) {
            //     this.wavyCollisionBoxes.push(this.wavyCollisionBox)
            // }
            
            y += this.h * sizes[i]
        }
    }

    // Builds a face with a grid pattern
    buildGridFace(x, y, z, w, h, l, stepX, stepY, rotation, partCount, forceEdges=false, verticalOverflow=false, polygonDistorsion=false) {
        let horizontalOverflow = brandom(b_settings.horizontal_overflow_density)
        let startY = y
        let stopRendering = false
        let lineColor = new Color(color_settings[rotation%PI === 0 ? 'linesA' : 'linesB'])

        // Horizontal Lines
        for (let j = startY; j <= y+h+0.01; j += stepY) {
            const width = w * (horizontalOverflow ?  random(0.9, 1.4) : random(1,1))
            const line = this.buildHorizontalLine(x, j, z+0.001, w, l, width, rotation)
            
            if (!stopRendering || (forceEdges && (j + stepY > y+h+0.01))) {
                if (color_settings.lines_gradient)
                    this.addInstancedObject(line, getInterpolatedColorFromGradient('rectangles', partCount, j, startY, y+h), polygonDistorsion ? 'polygon' : undefined)
                else 
                    this.addInstancedObject(line, lineColor)
            }

            if (brandom(map(b_settings.horizontal_lines_density, 0, 1, 1, 0.1))) stopRendering = true
        }

        let endX = w
        let overflow = random(x, endX-stepX)
        verticalOverflow = verticalOverflow ? overflow : null
        stopRendering = false
        
        // Vertical lines
        for (let i = 0; i <= endX+0.01; i += stepX) {
            let forceVerticalOverflow = verticalOverflow && i >= verticalOverflow && i < verticalOverflow + stepX
            const height = h * (forceVerticalOverflow ? random(1.5, 2.5) : random(1, 1))
            const line = this.buildVerticalLine(x+i, y, z, w, height, l, rotation)
            
            if (!stopRendering || forceVerticalOverflow || (forceEdges && (i + stepX > endX+0.01))) {
                if (color_settings.lines_gradient)
                    this.addInstancedObject(line, getGradient('rectangles', partCount), 'gradient', 3)
                else 
                    this.addInstancedObject(line, lineColor)
            }

            if (brandom(map(b_settings.vertical_lines_density, 0, 1, 1, 0.1))) stopRendering = true
        }
    }

    //////////// Building Functions ////////////

    buildFacades(x, y, z, w, h, l, angle) {
        let valid, current, exitLoop = 1000
        do {
            let rectW = w * random(0.6, 1)
            let rectH = h * random(0.6, 1)
            if (brandom()) {
                rectW = w 
                rectH = h * random(0.55, 0.85)
            } else {
                rectW = w * random(0.55, 0.85)
                rectH = h 
            }
            let rectX = random(0, w - rectW)
            let rectY = random(0, h - rectH)

            valid = true
            current = { x: rectX, y: rectY+y, w: rectW, h: rectH, angle }
            for (let facade of this.facades.filter(f => f.angle === angle)) {
                if (facade.x + facade.w > current.x && facade.x < current.x + current.w && 
                    facade.y + facade.h > current.y && facade.y < current.y + current.h) {
                        valid = false
                        break
                    }
            }
            exitLoop--
        } while(!valid && exitLoop > 0)
        if (!valid) console.log('NOT VALID', exitLoop)

        this.facades.push(current)
 
        let face = this.buildFace(x+current.x, current.y, z+random(0.1, 0.3), current.w, current.h, l, angle)
        this.addInstancedObject(face, getGradient('facadesRectangles'), 'gradient', 0, 0.8)
    }

    buildFacadeClosedRect(y, w, h, l) {
        for (let i = 0; i < 4; i++) {
            const angle = PI/2*i
            let newW = (i%2 === 0 ? w : l)
            let newL = (i%2 === 0 ? l : w)
            let face = this.buildFace(0, y, 0, newW, h, newL, angle)
            this.addInstancedObject(face, getGradient('facadesRectangles'), 'gradient', 1)
        }
    }

    
    buildHorizontalLine(x, y, z, w, l, lineWidth, rotation) {
        const line = new PlaneGeometry(lineWidth, this.stroke_width, this.resolution, 1)
        line.translate(x+lineWidth/2, y+this.stroke_width/2, z)
        line.translate(-w/2, 0, l/2)
        return line.rotateY(rotation)
    }

    buildVerticalLine(x, y, z, w, h, l, rotation) {
        const line = new PlaneGeometry(this.stroke_width, h, 1, this.resolution)
        line.translate(x+this.stroke_width/2, y+h/2, z)

        if (rotation == null) return line

        line.translate(-w/2, 0, l/2)
        return line.rotateY(rotation)
    }

    buildArc(x, y, z, r, h, startA, length) {
        const cylinder = new CylinderGeometry(r, r, h, 64, 1, true, startA, length)
        cylinder.translate(x, h/2+y, z)
        return cylinder
    }

    buildRotatedArc(rotation, y, r, h, startA, length) {
        const cylinder = new CylinderGeometry(r, r, h, 64, 1, true, startA, length)
        cylinder.rotateX(rotation)
        cylinder.translate(0, h/2+y, 0)
        return cylinder
    }

    buildFace(x, y, z, w, h, l, rotation) {
        const rect = new PlaneGeometry(w, h, this.resolution, this.resolution)
        rect.translate(x, h/2+y, l/2+z)
        rect.rotateY(rotation)  
        return rect
    }

    buildCylinder(x, y, z, r, h) {
        const cylinder = new CylinderGeometry(r, r, h, 64, 1, false)
        cylinder.translate(x, h/2+y, z)
        return cylinder
    }
}