import { b_settings, settings } from "./gui"

// function holdingPageParameters() {
//     settings.city_buildings = 
//     settings.city_spacing = 

//     settings.top_bending = 
//     settings.bottom_bending = 
//     settings.mini_density = 
//     settings.mini_different_type_density = 

//     settings.fog_density = 
//     settings.star_count = 
//     settings.environment_density = 

//     b_settings.type_rectangle_density = 
//     b_settings.type_cylinder_density = 
//     b_settings.type_triangle_density = 

//     b_settings.horizontal_lines_density = 
//     b_settings.vertical_lines_density = 
//     b_settings.horizontal_overflow_density = 
//     b_settings.vertical_overflow_density = 
//     b_settings.transparent_grid_density = 
//     b_settings.transparent_gradient_density = 
//     b_settings.gradient_style_density = 
//     b_settings.facades_density = 
//     b_settings.stroke_width = 

//     b_settings.wavy_density = 
//     settings.noiseScale = 
//     settings.noiseIntensity = 
//     settings.noiseResolution = 

//     b_settings.cylindric_type_density = r
//     b_settings.minimum_radius = 
//     b_settings.billboards_density = 
//     b_settings.floating_facades_density = 
//     b_settings.transparent_cylinders_density = 
//     b_settings.cylindric_lines_stepY = 
//     b_settings.cylindric_lines_height = 

//     b_settings.triangle_ring_density = 
//     b_settings.triangle_window_density = 
// }

function holdingPageParameters() {
    settings.city_buildings = 75
    settings.city_spacing = 0.4

    settings.top_bending = 2
    settings.bottom_bending = 0.55

    settings.mini_density = 0.65
    settings.mini_different_type_density = 0.7

    settings.fog_density = 0.3
    settings.star_count = 750
    settings.environment_density = 0.12

    b_settings.type_rectangle_density = 0.65
    b_settings.type_cylinder_density = 0.55
    b_settings.type_triangle_density = 0.8

    b_settings.horizontal_lines_density = 0.8
    b_settings.vertical_lines_density = 0.85
    b_settings.horizontal_overflow_density = 0.15
    b_settings.vertical_overflow_density = 0.65
    b_settings.transparent_grid_density = 0.15
    b_settings.transparent_gradient_density = 0.2
    b_settings.gradient_style_density = 0.1
    b_settings.facades_density = 0.9
    b_settings.stroke_width = 0.02

    b_settings.wavy_density = 0.6
    settings.noiseScale = 0.45
    settings.noiseIntensity = 1.1
    settings.noiseResolution = 18

    b_settings.cylindric_type_density = 0.5
    b_settings.minimum_radius = 0.5
    b_settings.billboards_density = 0.9
    b_settings.floating_facades_density = 0.7 
    b_settings.transparent_cylinders_density = 0.1
    b_settings.cylindric_lines_stepY = 0.095
    b_settings.cylindric_lines_height = 0.85

    b_settings.triangle_ring_density = 0.9
    b_settings.triangle_window_density = 0.1
}

function getInTouchPageParameters() {
    settings.city_buildings = 25
    settings.city_spacing = 0.35

    settings.top_bending = 1.75
    settings.bottom_bending = 0.65
    settings.mini_density = 0.65
    settings.mini_different_type_density = 0.35

    settings.fog_density = 0.3
    settings.star_count = 650
    settings.environment_density = 0.1

    b_settings.type_rectangle_density = 0.4
    b_settings.type_cylinder_density = 0.6
    b_settings.type_triangle_density = 0.5

    b_settings.horizontal_lines_density = 0.8
    b_settings.vertical_lines_density = 0.85
    b_settings.horizontal_overflow_density = 0.15
    b_settings.vertical_overflow_density = 0.65
    b_settings.transparent_grid_density = 0.7
    b_settings.transparent_gradient_density = 0.8
    b_settings.gradient_style_density = 0.1
    b_settings.facades_density = 0.8
    b_settings.stroke_width = 0.025

    b_settings.wavy_density = 0.5
    settings.noiseScale = 0.5
    settings.noiseIntensity = 0.8
    settings.noiseResolution = 20

    b_settings.cylindric_type_density = 0.5
    b_settings.minimum_radius = 0.5
    b_settings.billboards_density = 0.9
    b_settings.floating_facades_density = 0.7
    b_settings.transparent_cylinders_density = 0.1
    b_settings.cylindric_lines_stepY = 0.07
    b_settings.cylindric_lines_height = 1.25

    b_settings.triangle_ring_density = 0.65
    b_settings.triangle_window_density = 0.5
}

const holdingPagePalette = {
    rectangle: ["#BF538D", "#C34186", "#A02B2A", "#F575BB", "#E2BBC0", "#E79AFF", "#CA408D", "#E4B7E4"],
    cylinder: ["#7687AF", "#CAE0E6", "#50A4BA", "#79AEDC", "#AB7594", "#70609C", "#874DA9", "#BC56B6", "#78BED9", "#ACABE6", "#3696A3", "#C5D6E1"],
    triangle: ["#7DC297", "#253428", "#DFFEE9", "#689D6D", "#A1F2BF", "#4B643D", "#88CE98", "#6CB2AA", "#749F9E"],
    environment: ["#70609C", "#CA408D", "#F575BB", "#7DC297", "#253428", "#689D6D", "#E79AFF", "#E4B7E4"],
    background: ["#FCECEB"],
}

const firstPagePalette = {
    rectangle: ["#325369","#295993","#78ABB8","#163779","#CAE8E6","#4696A7","#30B6CE","#0A82B4","#B2CECE","#718F95","#A3AFA9"],
    cylinder:  ["#D25027","#E15D19","#CD3938","#BD2925","#8A1B22","#DFD3B7","#AC9A7A","#C69C87"],
    triangle: ["#EEB56F","#A7535D","#D490A1","#EDBEAD","#E7C2A7","#92677D","#FFC0A2","#E79075","#A32348","#F9C2CE","#FFC0BB"],
    environment: ["#EEB56F","#A7535D","#D490A1","#EDBEAD","#E7C2A7","#92677D","#FFC0A2","#E79075","#A32348","#F9C2CE","#FFC0BB"],
    background: ["#220206"],
}

const holdingPageDemo = {
    palette: holdingPagePalette,
    settings: holdingPageParameters,
    seed: 7647526783407915,
}

const firstPageDemo = {
    palette: firstPagePalette,
    settings: holdingPageParameters,
    seed: 7965677212392273,
}

const getInTouchDemo = {
    palette: firstPagePalette,
    settings: getInTouchPageParameters,
    seed: 7805134764464629,
}

const currentDemo = holdingPageDemo

export const demo = true

export const forcePalette = currentDemo.palette

export const forceSettings = currentDemo.settings

export const forceSeed = currentDemo.seed