import * as THREE from 'three'
import { Color, Vector2, Vector3 } from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { SSAARenderPass } from 'three/examples/jsm/postprocessing/SSAARenderPass.js'
import { animateCamera, animationClock } from './animations'
import City from './city'
import { demo } from './demo'
import { initGUI, settings } from './gui'
import './style.css'
import { PI, saveFile, updatePerformance } from './utils'

// Canvas
const canvas = document.querySelector('canvas.webgl')
export const scene = new THREE.Scene()
scene.background = new Color('transparent')

/**
 * Sizes
 */
let sizes = demo ? {
    width: window.innerWidth,
    height: window.innerHeight
} : {
    width: settings.width,
    height: settings.height
}

window.addEventListener('resize', () =>{
    updateSize(sizes.width, sizes.height)
})

/**
 * Camera
 */
// Base camera
export const DEFAULT_CAMERA_FOV = 90
export const camera = new THREE.PerspectiveCamera(90, sizes.width / sizes.height, 0.1, 1000)
// camera.position.setFromCylindrical(new THREE.Cylindrical(23, PI/4, 6))
camera.position.setFromCylindrical(new THREE.Cylindrical(23*0, PI/4, 27))
scene.add(camera)

// Controls
export const controls = new OrbitControls(camera, canvas)
controls.target = new Vector3(0, settings.city_height*0.85, 0)
controls.enableDamping = true
controls.maxDistance = 38
controls.enablePan = false
controls.updateEnabled = true

/**
 * Renderer
 */
export const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

export const isWebgl2 = renderer.capabilities.isWebGL2
console.log({isWebgl2})

const composer = new EffectComposer( renderer );
const ssaaRenderPassP = new SSAARenderPass( scene, camera );
ssaaRenderPassP.sampleLevel = 4
composer.addPass( ssaaRenderPassP );

/**
 * Animate
 */

export const tick = () => {
    animateCamera()

    // Update city animation
    if (city.buildings.filter(b => !b.mini).length < settings.city_buildings) {
        city.generateNext()
    }

    // Update Orbital Controls
    if (animationClock == null) controls.update()
    else camera.lookAt(controls.target)

    // Render
    // composer.render(scene, camera)
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

export let city

// Prevent black frame while loading
updateSize(sizes.width, sizes.height)
renderer.render(scene, camera)

window.onload = function() {
    updatePerformance('Script Start')
    city = new City()
    initGUI()
    tick()
}

// Image Saving

export function updateSize(width, height) {
    // Update sizes
    sizes.width = width
    sizes.height = height

    // Update camera
    let aspect = sizes.width / sizes.height
    camera.aspect = aspect
    camera.updateProjectionMatrix()

    // Update renderer
    let SCALING_FACTOR = window.innerWidth / width

    if (height * SCALING_FACTOR > window.innerHeight) {
        SCALING_FACTOR = window.innerHeight / height
        renderer.setSize(width*SCALING_FACTOR, window.innerHeight)
    } else {
        renderer.setSize(window.innerWidth, height*SCALING_FACTOR)
    }

    console.log('setViewport', sizes.width, sizes.height)
    renderer.setViewport(0, 0, sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
}

export function saveAsImage() {
    const oldPixelRatio = renderer.getPixelRatio()
    const oldSize = new Vector2()
    renderer.getSize(oldSize)

    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(settings.savePixelRatio)
    renderer.render(scene, camera)

    try {
        let strMime = "image/png";
        let imgData = renderer.domElement.toDataURL(strMime);

        saveFile(imgData.replace(strMime, "image/octet-stream"), "render.png");
    } catch (e) {
        console.log(e)
    }

    renderer.setSize(oldSize.x, oldSize.y)
    renderer.setPixelRatio(oldPixelRatio)
}